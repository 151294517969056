import React, { useState } from 'react';
import { Plus, Search, Edit2, Trash2, MessageSquare } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useProjectStore } from '../store/projectStore';
import { useAuthStore } from '../store/authStore';
import ProjectForm from '../components/ProjectForm';
import ProjectDiscussions from '../components/ProjectDiscussions';
import { format, parseISO } from 'date-fns';
import type { Project } from '../types';

const Projects: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { projects, addProject, updateProject, deleteProject } = useProjectStore();
  const [showModal, setShowModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDiscussions, setShowDiscussions] = useState(false);

  // Filter projects based on user role
  const filteredProjects = projects.filter(project => {
    // Basic search filter
    const matchesSearch = project.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.objective.toLowerCase().includes(searchTerm.toLowerCase());

    // Role-based filtering
    if (user?.role === 'contractor') {
      // Show only projects where the user is assigned as a contractor
      return matchesSearch && project.phases?.some(phase => phase.contractor === user.name);
    }

    return matchesSearch;
  });

  const handleCreateProject = () => {
    setSelectedProject(null);
    setShowModal(true);
  };

  const handleEditProject = (project: Project) => {
    setSelectedProject(project);
    setShowModal(true);
  };

  const handleDeleteProject = (projectId: string) => {
    if (window.confirm('Are you sure you want to delete this project?')) {
      deleteProject(projectId);
    }
  };

  const handleSubmit = (projectData: Partial<Project>) => {
    if (!user) return;

    if (selectedProject) {
      // Update existing project
      const updatedProject: Project = {
        ...selectedProject,
        ...projectData,
        teamMembers: projectData.teamMembers || selectedProject.teamMembers,
        phases: projectData.phases || selectedProject.phases,
        risks: projectData.risks || selectedProject.risks,
        files: projectData.files || selectedProject.files,
        discussions: selectedProject.discussions || []
      };
      updateProject(updatedProject);
    } else {
      // Create new project
      const newProject: Project = {
        id: Math.random().toString(36).substr(2, 9),
        ...projectData as Omit<Project, 'id' | 'createdAt' | 'createdBy' | 'creatorName'>,
        createdAt: new Date().toISOString(),
        createdBy: user.id,
        creatorName: user.name,
        discussions: [],
        files: projectData.files || [],
        teamMembers: projectData.teamMembers || [],
        phases: projectData.phases || [],
        risks: projectData.risks || []
      };
      addProject(newProject);
    }
    setShowModal(false);
    setSelectedProject(null);
  };

  const getStatusColor = (status: Project['status']) => {
    switch (status) {
      case 'completed':
        return 'bg-green-100 text-green-800';
      case 'in-progress':
        return 'bg-blue-100 text-blue-800';
      case 'on-hold':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Projects</h1>
        {user?.role !== 'contractor' && (
          <button
            onClick={handleCreateProject}
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            <Plus className="h-5 w-5 mr-2" />
            New Project
          </button>
        )}
      </div>

      <div className="bg-white rounded-lg p-4">
        <div className="flex items-center bg-gray-50 rounded-md px-4 py-2">
          <Search className="h-5 w-5 text-gray-400" />
          <input
            type="text"
            placeholder="Search projects..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="ml-2 flex-1 bg-transparent outline-none"
          />
        </div>
      </div>

      <div className="bg-white shadow rounded-lg divide-y divide-gray-200">
        {filteredProjects.map((project) => (
          <div key={project.id} className="p-6">
            <div className="flex justify-between items-start">
              <div 
                className="flex-1 cursor-pointer"
                onClick={() => navigate(`/projects/${project.id}`)}
              >
                <div className="flex items-center space-x-3">
                  <h3 className="text-lg font-medium text-gray-900">{project.title}</h3>
                  <span className={`px-2 py-1 text-xs rounded-full ${getStatusColor(project.status)}`}>
                    {project.status.replace('-', ' ').toUpperCase()}
                  </span>
                </div>
                <p className="mt-1 text-sm text-gray-500">{project.objective}</p>
                <div className="mt-2 flex items-center space-x-4 text-sm text-gray-500">
                  <span>Start: {format(parseISO(project.startDate), 'MMM d, yyyy')}</span>
                  <span>End: {format(parseISO(project.endDate), 'MMM d, yyyy')}</span>
                  <span>{project.teamMembers?.length || 0} team members</span>
                  <span>{project.phases?.length || 0} phases</span>
                  <span>{project.risks?.length || 0} risks</span>
                  <span>{project.discussions?.length || 0} discussions</span>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => {
                    setSelectedProject(project);
                    setShowDiscussions(true);
                  }}
                  className="p-2 text-gray-400 hover:text-gray-500"
                >
                  <MessageSquare className="h-5 w-5" />
                </button>
                {user?.role !== 'contractor' && (
                  <>
                    <button
                      onClick={() => handleEditProject(project)}
                      className="p-2 text-gray-400 hover:text-gray-500"
                    >
                      <Edit2 className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDeleteProject(project.id)}
                      className="p-2 text-red-400 hover:text-red-500"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}

        {filteredProjects.length === 0 && (
          <div className="p-6 text-center text-gray-500">
            No projects found
          </div>
        )}
      </div>

      {showModal && (
        <ProjectForm
          project={selectedProject}
          isOpen={showModal}
          onClose={() => {
            setShowModal(false);
            setSelectedProject(null);
          }}
          onSubmit={handleSubmit}
        />
      )}

      {showDiscussions && selectedProject && (
        <ProjectDiscussions
          project={selectedProject}
          onClose={() => {
            setShowDiscussions(false);
            setSelectedProject(null);
          }}
        />
      )}
    </div>
  );
};

export default Projects;