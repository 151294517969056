import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { AlertTriangle } from 'lucide-react';
import { useMachineStore } from '../store/machineStore';
import { useTaskStore } from '../store/taskStore';
import { startOfWeek, endOfWeek, parseISO, format, eachWeekOfInterval, subWeeks } from 'date-fns';

const Dashboard: React.FC = () => {
  const { machines } = useMachineStore();
  const { tasks } = useTaskStore();
  const nonWorkingMachines = machines.filter(machine => machine.status !== 'working');

  // Calculate task statistics for the last 6 weeks
  const now = new Date();
  const sixWeeksAgo = subWeeks(now, 5);
  const weekStarts = eachWeekOfInterval({
    start: sixWeeksAgo,
    end: now
  });

  const taskStats = weekStarts.map(weekStart => {
    const weekEnd = endOfWeek(weekStart);
    const weekTasks = tasks.filter(task => {
      const taskDate = parseISO(task.createdAt);
      return taskDate >= weekStart && taskDate <= weekEnd;
    });

    const openTasks = weekTasks.filter(task => task.status !== 'completed' && task.status !== 'rejected').length;
    const closedTasks = weekTasks.filter(task => task.status === 'completed').length;

    return {
      week: format(weekStart, 'MMM d'),
      open: openTasks,
      closed: closedTasks
    };
  });

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
      
      {/* Stats */}
      <div className="grid grid-cols-1 gap-5">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <AlertTriangle className="h-6 w-6 text-yellow-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Non-Working Machines
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">{nonWorkingMachines.length}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Task Statistics Chart */}
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4">Weekly Task Statistics</h2>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={taskStats}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="week" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar name="Open Tasks" dataKey="open" fill="#FFA500" />
              <Bar name="Closed Tasks" dataKey="closed" fill="#4CAF50" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Recent Logs */}
      <div className="bg-white shadow rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium text-gray-900">Recent Logs</h3>
        </div>
        <div className="border-t border-gray-200">
          <ul className="divide-y divide-gray-200">
            {[1, 2, 3, 4, 5].map((log) => (
              <li key={log} className="px-4 py-4 sm:px-6 hover:bg-gray-50">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <div className="h-8 w-8 rounded-full bg-blue-100 flex items-center justify-center">
                        <AlertTriangle className="h-5 w-5 text-blue-600" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <p className="text-sm font-medium text-gray-900">
                        Machine #{log} Status Update
                      </p>
                      <p className="text-sm text-gray-500">
                        Routine maintenance completed
                      </p>
                    </div>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <span className="text-sm text-gray-500">3h ago</span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;