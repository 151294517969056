import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Calendar, User, Building, Flag, X, MessageSquare } from 'lucide-react';
import { format, parseISO } from 'date-fns';
import { useAuthStore } from '../store/authStore';
import { useTaskStore } from '../store/taskStore';
import type { Task, TaskNote } from '../types';

const TaskDetails: React.FC = () => {
  const { taskId } = useParams<{ taskId: string }>();
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { tasks, addNote } = useTaskStore();
  const [newNote, setNewNote] = useState('');
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const task = tasks.find(t => t.id === taskId);

  if (!task) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-semibold text-gray-900">Task not found</h2>
        <button
          onClick={() => navigate('/tasks')}
          className="mt-4 inline-flex items-center text-blue-600 hover:text-blue-700"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Tasks
        </button>
      </div>
    );
  }

  const handleAddNote = () => {
    if (!user || !newNote.trim() || !task) return;

    const note: TaskNote = {
      id: Math.random().toString(36).substr(2, 9),
      taskId: task.id,
      content: newNote.trim(),
      createdAt: new Date().toISOString(),
      createdBy: user.id,
      creatorName: user.name,
      creatorAvatar: user.avatar
    };

    addNote(note);
    setNewNote('');
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <button
          onClick={() => navigate('/tasks')}
          className="inline-flex items-center text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Tasks
        </button>
        <span className={`px-2 py-1 text-xs rounded-full ${
          task.priority === 'high'
            ? 'bg-red-100 text-red-800'
            : task.priority === 'normal'
            ? 'bg-yellow-100 text-yellow-800'
            : 'bg-green-100 text-green-800'
        }`}>
          {task.priority.toUpperCase()}
        </span>
      </div>

      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="p-6">
          <div className="flex items-center justify-between mb-4">
            <h1 className="text-2xl font-semibold text-gray-900">
              #{task.taskNumber} - {task.title}
            </h1>
            <span className={`px-3 py-1 rounded-full text-sm ${
              task.status === 'completed'
                ? 'bg-green-100 text-green-800'
                : task.status === 'rejected'
                ? 'bg-red-100 text-red-800'
                : task.status === 'in-progress'
                ? 'bg-blue-100 text-blue-800'
                : 'bg-gray-100 text-gray-800'
            }`}>
              {task.status.toUpperCase()}
            </span>
          </div>

          <div className="space-y-4">
            <p className="text-gray-700">{task.description}</p>

            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center text-gray-600">
                <Calendar className="h-5 w-5 mr-2" />
                <span>Due: {format(parseISO(task.targetDate), 'MMM d, yyyy')}</span>
              </div>
              <div className="flex items-center text-gray-600">
                <User className="h-5 w-5 mr-2" />
                <span>Created by: {task.creatorName}</span>
              </div>
              <div className="flex items-center text-gray-600">
                <Building className="h-5 w-5 mr-2" />
                <span>Department: {task.department}</span>
              </div>
              <div className="flex items-center text-gray-600">
                <Flag className="h-5 w-5 mr-2" />
                <span>Priority: {task.priority}</span>
              </div>
            </div>

            {task.images && task.images.length > 0 && (
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-2">Images</h3>
                <div className="grid grid-cols-4 gap-4">
                  {task.images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Task ${task.taskNumber} - Image ${index + 1}`}
                      className="h-24 w-24 object-cover rounded-lg cursor-pointer hover:opacity-75"
                      onClick={() => setSelectedImage(image)}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Notes Section */}
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Notes</h3>
          
          {/* Add Note Form */}
          <div className="mb-6">
            <div className="flex items-start space-x-3">
              <img
                className="h-8 w-8 rounded-full"
                src={user?.avatar || "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"}
                alt={user?.name}
              />
              <div className="flex-1">
                <textarea
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                  placeholder="Add a note..."
                  className="w-full px-3 py-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                  rows={3}
                />
                <div className="mt-2 flex justify-end">
                  <button
                    onClick={handleAddNote}
                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center"
                    disabled={!newNote.trim()}
                  >
                    <MessageSquare className="h-4 w-4 mr-2" />
                    Add Note
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Notes List */}
          <div className="space-y-4">
            {task.notes.map((note) => (
              <div key={note.id} className="flex space-x-3">
                <img
                  className="h-8 w-8 rounded-full"
                  src={note.creatorAvatar || "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"}
                  alt={note.creatorName}
                />
                <div className="flex-1">
                  <div className="text-sm">
                    <span className="font-medium text-gray-900">{note.creatorName}</span>
                    <span className="text-gray-500 ml-2">
                      {format(parseISO(note.createdAt), 'MMM d, yyyy HH:mm')}
                    </span>
                  </div>
                  <div className="mt-1 text-sm text-gray-700 whitespace-pre-wrap">
                    {note.content}
                  </div>
                </div>
              </div>
            ))}

            {task.notes.length === 0 && (
              <p className="text-gray-500 text-sm text-center py-4">
                No notes yet. Be the first to add a note!
              </p>
            )}
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="relative max-w-4xl w-full">
            <button
              onClick={() => setSelectedImage(null)}
              className="absolute top-4 right-4 text-white hover:text-gray-300"
            >
              <X className="h-6 w-6" />
            </button>
            <img
              src={selectedImage}
              alt="Task"
              className="w-full h-auto rounded-lg"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskDetails;