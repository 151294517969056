import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { User } from '../types';

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  loading: boolean;
  error: string | null;
  users: { user: User }[];
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  updateUser: (user: User) => Promise<void>;
  addUser: (email: string, password: string, user: User) => void;
  deleteUser: (userId: string) => void;
}

const defaultAdminUser = {
  user: {
    id: '1',
    email: 'admin@example.com',
    name: 'Admin User',
    role: 'admin',
    lastLogin: new Date().toISOString(),
    avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
  }
};

export const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      user: null,
      isAuthenticated: false,
      loading: false,
      error: null,
      users: [defaultAdminUser],

      login: async (email: string, password: string) => {
        set({ loading: true, error: null });
        try {
          // Default admin login
          if (email === 'admin@example.com' && password === 'admin123') {
            const adminUser = get().users[0].user;
            set({
              user: adminUser,
              isAuthenticated: true,
              loading: false,
              error: null
            });
            return;
          }

          // Check other users
          const user = get().users.find(u => u.user.email === email)?.user;
          if (user) {
            set({
              user,
              isAuthenticated: true,
              loading: false,
              error: null
            });
            return;
          }

          throw new Error('Invalid login credentials');
        } catch (error) {
          set({ 
            error: error.message, 
            loading: false,
            isAuthenticated: false,
            user: null
          });
          throw error;
        }
      },

      logout: async () => {
        set({ 
          user: null, 
          isAuthenticated: false, 
          loading: false,
          error: null
        });
      },

      updateUser: async (updatedUser: User) => {
        set(state => ({
          users: state.users.map(u => 
            u.user.id === updatedUser.id ? { user: updatedUser } : u
          ),
          user: state.user?.id === updatedUser.id ? updatedUser : state.user
        }));
      },

      addUser: (email: string, password: string, user: User) => {
        set(state => ({
          users: [...state.users, { user }]
        }));
      },

      deleteUser: (userId: string) => {
        set(state => ({
          users: state.users.filter(u => u.user.id !== userId)
        }));
      }
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({
        user: state.user,
        isAuthenticated: state.isAuthenticated,
        users: state.users
      })
    }
  )
);