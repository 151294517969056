import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Plus, Search, Calendar, AlertCircle, MessageSquare, X, Edit2 } from 'lucide-react';
import { format, isWithinInterval, parseISO, startOfDay, endOfDay } from 'date-fns';
import { useAuthStore } from '../store/authStore';
import { useMachineStore } from '../store/machineStore';
import type { Fault, FaultComment } from '../types';

const getPriorityColor = (priority: Fault['priority']) => {
  switch (priority) {
    case 'high':
      return 'bg-red-100 text-red-800';
    case 'normal':
      return 'bg-yellow-100 text-yellow-800';
    case 'low':
      return 'bg-green-100 text-green-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
};

const MachineFaults: React.FC = () => {
  const navigate = useNavigate();
  const { machineId } = useParams<{ machineId: string }>();
  const { user } = useAuthStore();
  const { getFaultsByMachineId, addFault, updateFault, addComment } = useMachineStore();
  const [showNewFaultModal, setShowNewFaultModal] = useState(false);
  const [showEditFaultModal, setShowEditFaultModal] = useState(false);
  const [editingFault, setEditingFault] = useState<Fault | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [newComment, setNewComment] = useState('');
  const [selectedFaultId, setSelectedFaultId] = useState<string | null>(null);
  const [newFault, setNewFault] = useState<Partial<Fault>>({
    description: '',
    priority: 'normal',
    notes: ''
  });

  const faults = getFaultsByMachineId(machineId || '');

  const filteredFaults = faults.filter(fault => {
    const matchesSearch = fault.description.toLowerCase().includes(searchTerm.toLowerCase());
    
    let matchesDateRange = true;
    if (startDate && endDate) {
      const faultDate = parseISO(fault.createdAt);
      matchesDateRange = isWithinInterval(faultDate, {
        start: startOfDay(parseISO(startDate)),
        end: endOfDay(parseISO(endDate))
      });
    }

    return matchesSearch && matchesDateRange;
  });

  const handleAddFault = (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !machineId) return;

    const fault: Fault = {
      id: Math.random().toString(36).substr(2, 9),
      machineId,
      description: newFault.description || '',
      priority: newFault.priority || 'normal',
      status: 'pending',
      createdAt: new Date().toISOString(),
      createdBy: user.id,
      creatorName: user.name,
      notes: newFault.notes || '',
      comments: []
    };

    addFault(fault);
    setShowNewFaultModal(false);
    setNewFault({
      description: '',
      priority: 'normal',
      notes: ''
    });
  };

  const handleEditFault = (fault: Fault) => {
    setEditingFault(fault);
    setShowEditFaultModal(true);
  };

  const handleUpdateFault = (e: React.FormEvent) => {
    e.preventDefault();
    if (!editingFault) return;

    updateFault(editingFault);
    setShowEditFaultModal(false);
    setEditingFault(null);
  };

  const handleResolveFault = (fault: Fault) => {
    if (!user) return;

    const updatedFault: Fault = {
      ...fault,
      status: 'resolved',
      resolvedAt: new Date().toISOString(),
      resolvedBy: user.id,
      resolvedByName: user.name
    };

    updateFault(updatedFault);
  };

  const handleAddComment = (faultId: string) => {
    if (!user || !newComment.trim()) return;

    const comment: FaultComment = {
      id: Math.random().toString(36).substr(2, 9),
      faultId,
      content: newComment.trim(),
      createdAt: new Date().toISOString(),
      userId: user.id,
      userName: user.name,
      userAvatar: user.avatar
    };

    addComment(comment);
    setNewComment('');
    setSelectedFaultId(null);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Machine Faults</h1>
        <button
          onClick={() => setShowNewFaultModal(true)}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          <Plus className="h-5 w-5 mr-2" />
          Report Fault
        </button>
      </div>

      {/* Search and Filter Section */}
      <div className="bg-white rounded-lg p-4 space-y-4">
        <div className="flex items-center space-x-4">
          <div className="flex-1 flex items-center bg-gray-50 rounded-md px-4 py-2">
            <Search className="h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search faults..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="ml-2 flex-1 bg-transparent outline-none"
            />
          </div>
          <div className="flex items-center space-x-2">
            <Calendar className="h-5 w-5 text-gray-400" />
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="border rounded-md px-2 py-1"
            />
            <span>to</span>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="border rounded-md px-2 py-1"
            />
          </div>
        </div>
      </div>

      {/* Faults List */}
      <div className="bg-white shadow rounded-lg divide-y divide-gray-200">
        {filteredFaults.map((fault) => (
          <div key={fault.id} className="p-6 space-y-4">
            <div className="flex items-start justify-between">
              <div className="space-y-1">
                <div className="flex items-center space-x-2">
                  <AlertCircle className={`h-5 w-5 ${fault.priority === 'high' ? 'text-red-500' : 'text-yellow-500'}`} />
                  <span className={`px-2 py-1 text-xs rounded-full ${getPriorityColor(fault.priority)}`}>
                    {fault.priority.toUpperCase()}
                  </span>
                  <span className={`px-2 py-1 text-xs rounded-full ${
                    fault.status === 'resolved' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                  }`}>
                    {fault.status.toUpperCase()}
                  </span>
                </div>
                <p className="text-gray-900">{fault.description}</p>
                {fault.notes && (
                  <p className="text-sm text-gray-500">{fault.notes}</p>
                )}
                <p className="text-xs text-gray-400">
                  Reported on {format(parseISO(fault.createdAt), 'MMM d, yyyy HH:mm')}
                </p>
              </div>
              <div className="flex items-center space-x-2">
                {user?.role === 'admin' && fault.status !== 'resolved' && (
                  <>
                    <button
                      onClick={() => handleEditFault(fault)}
                      className="p-2 text-gray-400 hover:text-gray-500"
                    >
                      <Edit2 className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleResolveFault(fault)}
                      className="px-4 py-2 bg-green-600 text-white text-sm font-medium rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                      Mark as Resolved
                    </button>
                  </>
                )}
              </div>
            </div>

            {/* Comments Section */}
            <div className="mt-4 space-y-4">
              {fault.comments.map((comment) => (
                <div key={comment.id} className="flex space-x-3">
                  <img
                    className="h-8 w-8 rounded-full"
                    src={comment.userAvatar || "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"}
                    alt={comment.userName}
                  />
                  <div className="flex-1">
                    <div className="text-sm">
                      <span className="font-medium text-gray-900">{comment.userName}</span>
                      <span className="text-gray-500 ml-2">
                        {format(parseISO(comment.createdAt), 'MMM d, yyyy HH:mm')}
                      </span>
                    </div>
                    <p className="mt-1 text-sm text-gray-700">{comment.content}</p>
                  </div>
                </div>
              ))}

              {selectedFaultId === fault.id ? (
                <div className="flex items-start space-x-3">
                  <img
                    className="h-8 w-8 rounded-full"
                    src={user?.avatar || "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"}
                    alt={user?.name}
                  />
                  <div className="flex-1">
                    <textarea
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      placeholder="Add a comment..."
                      className="w-full px-3 py-2 border rounded-md"
                      rows={2}
                    />
                    <div className="mt-2 flex justify-end space-x-2">
                      <button
                        onClick={() => {
                          setNewComment('');
                          setSelectedFaultId(null);
                        }}
                        className="px-3 py-1 text-sm text-gray-600 hover:text-gray-800"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => handleAddComment(fault.id)}
                        className="px-3 py-1 bg-blue-600 text-white text-sm rounded hover:bg-blue-700"
                      >
                        Comment
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <button
                  onClick={() => setSelectedFaultId(fault.id)}
                  className="flex items-center text-sm text-gray-500 hover:text-gray-700"
                >
                  <MessageSquare className="h-4 w-4 mr-1" />
                  Add Comment
                </button>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* New Fault Modal */}
      {showNewFaultModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-900">Report New Fault</h3>
              <button
                onClick={() => setShowNewFaultModal(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="h-6 w-6" />
              </button>
            </div>
            <form onSubmit={handleAddFault} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Description</label>
                <textarea
                  value={newFault.description}
                  onChange={(e) => setNewFault({ ...newFault, description: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  rows={3}
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Priority</label>
                <select
                  value={newFault.priority}
                  onChange={(e) => setNewFault({ ...newFault, priority: e.target.value as Fault['priority'] })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                >
                  <option value="low">Low</option>
                  <option value="normal">Normal</option>
                  <option value="high">High</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Notes</label>
                <textarea
                  value={newFault.notes}
                  onChange={(e) => setNewFault({ ...newFault, notes: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  rows={2}
                />
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setShowNewFaultModal(false)}
                  className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-md text-sm font-medium hover:bg-blue-700"
                >
                  Report Fault
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Edit Fault Modal */}
      {showEditFaultModal && editingFault && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-900">Edit Fault</h3>
              <button
                onClick={() => {
                  setShowEditFaultModal(false);
                  setEditingFault(null);
                }}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="h-6 w-6" />
              </button>
            </div>
            <form onSubmit={handleUpdateFault} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Description</label>
                <textarea
                  value={editingFault.description}
                  onChange={(e) => setEditingFault({ ...editingFault, description: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  rows={3}
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Priority</label>
                <select
                  value={editingFault.priority}
                  onChange={(e) => setEditingFault({ ...editingFault, priority: e.target.value as Fault['priority'] })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                >
                  <option value="low">Low</option>
                  <option value="normal">Normal</option>
                  <option value="high">High</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Notes</label>
                <textarea
                  value={editingFault.notes}
                  onChange={(e) => setEditingFault({ ...editingFault, notes: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  rows={2}
                />
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => {
                    setShowEditFaultModal(false);
                    setEditingFault(null);
                  }}
                  className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-md text-sm font-medium hover:bg-blue-700"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default MachineFaults;