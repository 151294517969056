import React, { useState } from 'react';
import { Globe, Lock, Save } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSettingsStore } from '../store/settingsStore';
import { useAuthStore } from '../store/authStore';

const Settings: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { user } = useAuthStore();
  const { timeZone, language, updateSettings } = useSettingsStore();
  const [hasChanges, setHasChanges] = useState(false);
  const [settings, setSettings] = useState({
    language: i18n.language,
    timeZone,
  });

  const handleChange = (field: keyof typeof settings, value: any) => {
    setSettings(prev => ({
      ...prev,
      [field]: value
    }));
    setHasChanges(true);
  };

  const handleSave = () => {
    if (settings.language !== i18n.language) {
      i18n.changeLanguage(settings.language);
      document.documentElement.dir = settings.language === 'he' ? 'rtl' : 'ltr';
      document.documentElement.lang = settings.language;
    }
    
    updateSettings({
      language: settings.language,
      timeZone: settings.timeZone,
    });
    
    setHasChanges(false);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">{t('settings.title')}</h1>
        {hasChanges && (
          <button
            onClick={handleSave}
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            <Save className="h-5 w-5 mr-2" />
            {t('common.save')}
          </button>
        )}
      </div>
      
      <div className="bg-white shadow rounded-lg divide-y divide-gray-200">
        <div className="p-6">
          <div className="flex items-center">
            <Globe className="h-6 w-6 text-gray-400" />
            <h3 className="mr-3 text-lg font-medium text-gray-900">{t('settings.language')}</h3>
          </div>
          <div className="mt-4 space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t('settings.language')}
              </label>
              <select
                value={settings.language}
                onChange={(e) => handleChange('language', e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              >
                <option value="en">{t('settings.languages.en')}</option>
                <option value="he">{t('settings.languages.he')}</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t('settings.timeZone')}
              </label>
              <select
                value={settings.timeZone}
                onChange={(e) => handleChange('timeZone', e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              >
                <option value="UTC">UTC</option>
                <option value="UTC+2">UTC+2</option>
                <option value="UTC+3">UTC+3</option>
              </select>
            </div>
          </div>
        </div>

        <div className="p-6">
          <div className="flex items-center">
            <Lock className="h-6 w-6 text-gray-400" />
            <h3 className="mr-3 text-lg font-medium text-gray-900">{t('settings.security')}</h3>
          </div>
          <div className="mt-4">
            <button 
              onClick={() => handleChange('showPasswordModal', true)}
              className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
            >
              {t('settings.changePassword')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;