import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { Machine, Fault, FaultComment } from '../types';

interface MachineState {
  machines: Machine[];
  faults: Fault[];
  updateMachine: (machine: Machine) => void;
  addMachine: (machine: Machine) => void;
  deleteMachine: (machineId: string) => void;
  addFault: (fault: Fault) => void;
  updateFault: (fault: Fault) => void;
  getFaultsByMachineId: (machineId: string) => Fault[];
  addComment: (comment: FaultComment) => void;
}

export const useMachineStore = create<MachineState>()(
  persist(
    (set, get) => ({
      machines: [
        {
          id: '1',
          serialNumber: 'M001',
          name: 'Production Line A',
          status: 'working',
          lastUpdate: new Date().toISOString(),
          type: 'Assembly',
          location: 'Floor 1',
          maintenanceSchedule: 'quarterly',
          nextMaintenanceDate: '2024-04-01'
        },
        {
          id: '2',
          serialNumber: 'M002',
          name: 'Packaging Unit B',
          status: 'maintenance',
          lastUpdate: new Date().toISOString(),
          type: 'Packaging',
          location: 'Floor 2',
          maintenanceSchedule: 'annual',
          nextMaintenanceDate: '2024-12-01'
        }
      ],
      faults: [],
      updateMachine: (updatedMachine) =>
        set((state) => ({
          machines: state.machines.map((machine) =>
            machine.id === updatedMachine.id ? updatedMachine : machine
          ),
        })),
      addMachine: (machine) => {
        set((state) => ({
          machines: [...state.machines, machine],
        }));
      },
      deleteMachine: (machineId) =>
        set((state) => ({
          machines: state.machines.filter((machine) => machine.id !== machineId),
          faults: state.faults.filter((fault) => fault.machineId !== machineId),
        })),
      addFault: (fault) => {
        set((state) => ({
          faults: [...state.faults, fault],
        }));
      },
      updateFault: (updatedFault) =>
        set((state) => ({
          faults: state.faults.map((fault) =>
            fault.id === updatedFault.id ? updatedFault : fault
          ),
        })),
      getFaultsByMachineId: (machineId) => {
        return get().faults.filter((fault) => fault.machineId === machineId);
      },
      addComment: (comment) =>
        set((state) => ({
          faults: state.faults.map((fault) =>
            fault.id === comment.faultId
              ? { ...fault, comments: [...fault.comments, comment] }
              : fault
          ),
        })),
    }),
    {
      name: 'machine-storage',
    }
  )
);