import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Search, Edit2, Trash2, X } from 'lucide-react';
import { useMachineStore } from '../store/machineStore';
import { useAuthStore } from '../store/authStore';
import { useTranslation } from 'react-i18next';
import type { Machine } from '../types';

interface MachineModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (machineData: Omit<Machine, 'id'>) => void;
  machine?: Machine;
  mode: 'create' | 'edit';
}

const MachineModal: React.FC<MachineModalProps> = ({ isOpen, onClose, onSave, machine, mode }) => {
  const { t } = useTranslation();
  const [machineData, setMachineData] = useState({
    serialNumber: machine?.serialNumber || '',
    name: machine?.name || '',
    status: machine?.status || 'working',
    type: machine?.type || '',
    location: machine?.location || '',
    maintenanceSchedule: machine?.maintenanceSchedule || 'none',
    nextMaintenanceDate: machine?.nextMaintenanceDate || ''
  });

  if (!isOpen) return null;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave({
      ...machineData,
      lastUpdate: new Date().toISOString()
    });
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-gray-900">
            {mode === 'create' ? 'Add Machine' : 'Edit Machine'}
          </h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Serial Number</label>
            <input
              type="text"
              value={machineData.serialNumber}
              onChange={(e) => setMachineData({ ...machineData, serialNumber: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              value={machineData.name}
              onChange={(e) => setMachineData({ ...machineData, name: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Status</label>
            <select
              value={machineData.status}
              onChange={(e) => setMachineData({ ...machineData, status: e.target.value as Machine['status'] })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            >
              <option value="working">Working</option>
              <option value="maintenance">Maintenance</option>
              <option value="not-working">Not Working</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Type</label>
            <input
              type="text"
              value={machineData.type}
              onChange={(e) => setMachineData({ ...machineData, type: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Location</label>
            <input
              type="text"
              value={machineData.location}
              onChange={(e) => setMachineData({ ...machineData, location: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Maintenance Schedule</label>
            <select
              value={machineData.maintenanceSchedule}
              onChange={(e) => setMachineData({ ...machineData, maintenanceSchedule: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            >
              <option value="none">None</option>
              <option value="quarterly">Quarterly</option>
              <option value="semiannual">Semi-annual</option>
              <option value="annual">Annual</option>
            </select>
          </div>

          {machineData.maintenanceSchedule !== 'none' && (
            <div>
              <label className="block text-sm font-medium text-gray-700">Next Maintenance Date</label>
              <input
                type="date"
                value={machineData.nextMaintenanceDate}
                onChange={(e) => setMachineData({ ...machineData, nextMaintenanceDate: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                required
              />
            </div>
          )}

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md text-sm font-medium hover:bg-blue-700"
            >
              {mode === 'create' ? 'Add Machine' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const Machines: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const { machines, updateMachine, addMachine, deleteMachine, getFaultsByMachineId } = useMachineStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedMachine, setSelectedMachine] = useState<Machine | undefined>();
  const [modalMode, setModalMode] = useState<'create' | 'edit'>('create');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<string | null>(null);

  const hasUnresolvedFaults = (machineId: string) => {
    const faults = getFaultsByMachineId(machineId);
    return faults.some(fault => fault.status !== 'resolved');
  };

  const filteredMachines = machines.filter(machine => 
    machine.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    machine.serialNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
    machine.location.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCreateMachine = () => {
    setModalMode('create');
    setSelectedMachine(undefined);
    setShowModal(true);
  };

  const handleEditMachine = (machine: Machine, e: React.MouseEvent) => {
    e.stopPropagation();
    setModalMode('edit');
    setSelectedMachine(machine);
    setShowModal(true);
  };

  const handleSaveMachine = (machineData: Omit<Machine, 'id'>) => {
    if (modalMode === 'create') {
      addMachine({
        id: Math.random().toString(36).substr(2, 9),
        ...machineData
      });
    } else if (selectedMachine) {
      updateMachine({
        ...selectedMachine,
        ...machineData
      });
    }
    setShowModal(false);
  };

  const handleMachineClick = (machineId: string) => {
    navigate(`/machines/${machineId}/faults`);
  };

  const handleDeleteMachine = (machineId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    setShowDeleteConfirm(machineId);
  };

  const confirmDelete = () => {
    if (showDeleteConfirm) {
      deleteMachine(showDeleteConfirm);
      setShowDeleteConfirm(null);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Machines</h1>
        {user?.role === 'admin' && (
          <button
            onClick={handleCreateMachine}
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            <Plus className="h-5 w-5 mr-2" />
            Add Machine
          </button>
        )}
      </div>

      <div className="bg-white rounded-lg p-4">
        <div className="flex items-center bg-gray-50 rounded-md px-4 py-2">
          <Search className="h-5 w-5 text-gray-400" />
          <input
            type="text"
            placeholder="Search machines..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="ml-2 flex-1 bg-transparent outline-none"
          />
        </div>
      </div>

      <div className="bg-white shadow rounded-lg">
        <ul className="divide-y divide-gray-200">
          {filteredMachines.map((machine) => {
            const hasUnresolved = hasUnresolvedFaults(machine.id);
            const bgColor = hasUnresolved 
              ? 'bg-red-50'
              : machine.status === 'maintenance'
              ? 'bg-yellow-50'
              : machine.status === 'not-working'
              ? 'bg-red-50'
              : '';

            return (
              <li
                key={machine.id}
                onClick={() => handleMachineClick(machine.id)}
                className={`px-4 py-4 sm:px-6 hover:bg-gray-50 cursor-pointer ${bgColor}`}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center min-w-0">
                    <div className="flex-shrink-0">
                      <div className={`h-12 w-12 rounded-lg ${
                        hasUnresolved ? 'bg-red-100' : 'bg-gray-100'
                      } flex items-center justify-center`}>
                        <span className={`text-lg font-medium ${
                          hasUnresolved ? 'text-red-600' : 'text-gray-600'
                        }`}>
                          {machine.name.charAt(0)}
                        </span>
                      </div>
                    </div>
                    <div className="ml-4">
                      <p className={`text-sm font-medium ${
                        hasUnresolved ? 'text-red-900' : 'text-gray-900'
                      } truncate`}>
                        {machine.name}
                      </p>
                      <p className={`text-sm ${
                        hasUnresolved ? 'text-red-500' : 'text-gray-500'
                      }`}>
                        SN: {machine.serialNumber} | {machine.location}
                      </p>
                      <p className="text-xs text-gray-400 mt-1">
                        Last Update: {new Date(machine.lastUpdate).toLocaleString()}
                      </p>
                      {hasUnresolved && (
                        <p className="text-xs text-red-600 font-medium mt-1">
                          Has unresolved faults
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <select
                      value={machine.status}
                      onChange={(e) => {
                        e.stopPropagation();
                        updateMachine({
                          ...machine,
                          status: e.target.value as Machine['status'],
                          lastUpdate: new Date().toISOString()
                        });
                      }}
                      className={`px-2 py-1 text-xs rounded-full ${
                        machine.status === 'working'
                          ? 'bg-green-100 text-green-800'
                          : machine.status === 'maintenance'
                          ? 'bg-yellow-300 text-yellow-800'
                          : 'bg-red-400 text-white'
                      } border-0 cursor-pointer`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <option value="working">Working</option>
                      <option value="maintenance">Maintenance</option>
                      <option value="not-working">Not Working</option>
                    </select>
                    {user?.role === 'admin' && (
                      <>
                        <button
                          onClick={(e) => handleEditMachine(machine, e)}
                          className="text-gray-400 hover:text-gray-500"
                        >
                          <Edit2 className="h-5 w-5" />
                        </button>
                        <button
                          onClick={(e) => handleDeleteMachine(machine.id, e)}
                          className="text-red-400 hover:text-red-500"
                        >
                          <Trash2 className="h-5 w-5" />
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      {showModal && (
        <MachineModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onSave={handleSaveMachine}
          machine={selectedMachine}
          mode={modalMode}
        />
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-900">Delete Machine</h3>
              <button
                onClick={() => setShowDeleteConfirm(null)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            <p className="text-sm text-gray-500 mb-4">
              Are you sure you want to delete this machine? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowDeleteConfirm(null)}
                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={confirmDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-md text-sm font-medium hover:bg-red-700"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Machines;