import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, DollarSign, Users, Calendar, FileText } from 'lucide-react';
import { useProjectStore } from '../store/projectStore';
import { format, parseISO } from 'date-fns';

const ProjectDetails: React.FC = () => {
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();
  const { projects } = useProjectStore();
  const project = projects.find(p => p.id === projectId);

  if (!project) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-semibold text-gray-900">Project not found</h2>
        <button
          onClick={() => navigate('/projects')}
          className="mt-4 inline-flex items-center text-blue-600 hover:text-blue-700"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Projects
        </button>
      </div>
    );
  }

  // Calculate used budget from completed and in-progress phases
  const calculateUsedBudget = () => {
    return project.phases?.reduce((total, phase) => {
      if (phase.status === 'completed' || phase.status === 'in-progress') {
        return total + (phase.cost || 0);
      }
      return total;
    }, 0) || 0;
  };

  const usedBudget = calculateUsedBudget();
  const remainingBudget = (project.budget || 0) - usedBudget;
  const budgetUsagePercentage = ((usedBudget / (project.budget || 1)) * 100).toFixed(1);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <button
          onClick={() => navigate('/projects')}
          className="inline-flex items-center text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Projects
        </button>
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <h1 className="text-2xl font-semibold text-gray-900 mb-4">{project.title}</h1>
        <p className="text-gray-600">{project.objective}</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Budget Card */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex items-center">
            <DollarSign className="h-8 w-8 text-green-500" />
            <h3 className="ml-3 text-lg font-medium text-gray-900">Budget</h3>
          </div>
          <div className="mt-4 space-y-3">
            <div>
              <div className="flex justify-between items-baseline">
                <p className="text-3xl font-semibold text-gray-900">
                  ${project.budget?.toLocaleString()}
                </p>
                <p className="text-sm text-gray-500">Total Budget</p>
              </div>
              <div className="mt-2">
                <div className="flex justify-between text-sm">
                  <span className="text-gray-500">Used Budget</span>
                  <span className="font-medium text-blue-600">${usedBudget.toLocaleString()} ({budgetUsagePercentage}%)</span>
                </div>
                <div className="mt-1 h-2 bg-gray-200 rounded-full overflow-hidden">
                  <div 
                    className={`h-full rounded-full ${
                      parseFloat(budgetUsagePercentage) > 90 ? 'bg-red-500' :
                      parseFloat(budgetUsagePercentage) > 70 ? 'bg-yellow-500' :
                      'bg-blue-500'
                    }`}
                    style={{ width: `${Math.min(parseFloat(budgetUsagePercentage), 100)}%` }}
                  />
                </div>
              </div>
              <div className="mt-2 flex justify-between text-sm">
                <span className="text-gray-500">Remaining</span>
                <span className={`font-medium ${remainingBudget < 0 ? 'text-red-600' : 'text-green-600'}`}>
                  ${remainingBudget.toLocaleString()}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Team Members Card */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex items-center">
            <Users className="h-8 w-8 text-blue-500" />
            <h3 className="ml-3 text-lg font-medium text-gray-900">Team Members</h3>
          </div>
          <div className="mt-4">
            <div className="flow-root">
              <ul className="-my-2">
                {project.teamMembers?.map((member) => (
                  <li key={member.id} className="py-2">
                    <div className="flex items-center space-x-3">
                      <img
                        className="h-8 w-8 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt={member.name}
                      />
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-gray-900 truncate">
                          {member.name}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Timeline Card */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex items-center">
            <Calendar className="h-8 w-8 text-purple-500" />
            <h3 className="ml-3 text-lg font-medium text-gray-900">Timeline</h3>
          </div>
          <div className="mt-4 space-y-2">
            <div className="flex justify-between text-sm">
              <span className="text-gray-500">Start Date</span>
              <span className="font-medium">{format(parseISO(project.startDate), 'MMM d, yyyy')}</span>
            </div>
            <div className="flex justify-between text-sm">
              <span className="text-gray-500">End Date</span>
              <span className="font-medium">{format(parseISO(project.endDate), 'MMM d, yyyy')}</span>
            </div>
            <div className="flex justify-between text-sm">
              <span className="text-gray-500">Status</span>
              <span className={`px-2 py-1 text-xs rounded-full ${
                project.status === 'completed' ? 'bg-green-100 text-green-800' :
                project.status === 'in-progress' ? 'bg-blue-100 text-blue-800' :
                project.status === 'on-hold' ? 'bg-yellow-100 text-yellow-800' :
                'bg-gray-100 text-gray-800'
              }`}>
                {project.status.replace('-', ' ').toUpperCase()}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Project Details */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Phases */}
        <div className="bg-white shadow rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Project Phases</h3>
          <div className="space-y-4">
            {project.phases?.map((phase, index) => (
              <div key={phase.id} className="border rounded-lg p-4">
                <div className="flex justify-between items-center mb-2">
                  <h4 className="font-medium text-gray-900">Phase {index + 1}: {phase.name}</h4>
                  <span className={`px-2 py-1 text-xs rounded-full ${
                    phase.status === 'completed' ? 'bg-green-100 text-green-800' :
                    phase.status === 'in-progress' ? 'bg-blue-100 text-blue-800' :
                    'bg-gray-100 text-gray-800'
                  }`}>
                    {phase.status.toUpperCase()}
                  </span>
                </div>
                <p className="text-sm text-gray-600 mb-2">{phase.description}</p>
                <div className="grid grid-cols-2 gap-4 text-sm">
                  <div>
                    <span className="text-gray-500">Start:</span>{' '}
                    {format(parseISO(phase.startDate), 'MMM d, yyyy')}
                  </div>
                  <div>
                    <span className="text-gray-500">End:</span>{' '}
                    {format(parseISO(phase.endDate), 'MMM d, yyyy')}
                  </div>
                  <div>
                    <span className="text-gray-500">Cost:</span>{' '}
                    ${phase.cost?.toLocaleString()}
                  </div>
                  <div>
                    <span className="text-gray-500">Contractor:</span>{' '}
                    {phase.contractor || 'Not assigned'}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Risks */}
        <div className="bg-white shadow rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Project Risks</h3>
          <div className="space-y-4">
            {project.risks?.map((risk) => (
              <div key={risk.id} className="border rounded-lg p-4">
                <p className="text-sm text-gray-900 mb-2">{risk.description}</p>
                <div className="grid grid-cols-2 gap-4 text-sm">
                  <div>
                    <span className="text-gray-500">Impact:</span>{' '}
                    <span className={`px-2 py-1 text-xs rounded-full ${
                      risk.impact === 'high' ? 'bg-red-100 text-red-800' :
                      risk.impact === 'medium' ? 'bg-yellow-100 text-yellow-800' :
                      'bg-green-100 text-green-800'
                    }`}>
                      {risk.impact.toUpperCase()}
                    </span>
                  </div>
                  <div>
                    <span className="text-gray-500">Probability:</span>{' '}
                    <span className={`px-2 py-1 text-xs rounded-full ${
                      risk.probability === 'high' ? 'bg-red-100 text-red-800' :
                      risk.probability === 'medium' ? 'bg-yellow-100 text-yellow-800' :
                      'bg-green-100 text-green-800'
                    }`}>
                      {risk.probability.toUpperCase()}
                    </span>
                  </div>
                </div>
                <p className="text-sm text-gray-600 mt-2">
                  <span className="text-gray-500">Mitigation:</span>{' '}
                  {risk.mitigation}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Files */}
      {project.files && project.files.length > 0 && (
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex items-center mb-4">
            <FileText className="h-6 w-6 text-gray-400" />
            <h3 className="ml-2 text-lg font-medium text-gray-900">Project Files</h3>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {project.files.map((file) => (
              <div key={file.id} className="group relative">
                <a
                  href={file.url}
                  download={file.name}
                  className="block"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="aspect-square border rounded-lg overflow-hidden bg-gray-50 group-hover:bg-gray-100">
                    {file.type.startsWith('image/') ? (
                      <img
                        src={file.url}
                        alt={file.name}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center">
                        <FileText className="h-12 w-12 text-gray-400" />
                      </div>
                    )}
                  </div>
                  <p className="mt-1 text-sm text-gray-500 truncate">{file.name}</p>
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectDetails;