import React from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { LayoutDashboard, Settings, Server, Bell, Users, LogOut, User, CheckSquare, BarChart2, FolderKanban, HelpCircle, ShoppingCart, Package } from 'lucide-react';
import { useAuthStore } from '../store/authStore';
import { useTranslation } from 'react-i18next';

const Layout: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useAuthStore();
  const { t, i18n } = useTranslation();
  const isAdmin = user?.role === 'admin';

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const navigation = [
    { name: t('common.dashboard'), href: '/', icon: LayoutDashboard },
    { name: t('common.machines'), href: '/machines', icon: Server },
    { name: t('common.tasks'), href: '/tasks', icon: CheckSquare },
    { name: t('common.alerts'), href: '/alerts', icon: Bell },
    { name: t('common.reports'), href: '/reports', icon: BarChart2 },
    { name: i18n.language === 'he' ? 'פרוייקטים' : 'Projects', href: '/projects', icon: FolderKanban },
    { name: t('common.orders'), href: '/orders', icon: ShoppingCart },
    { name: 'Inventory', href: '/inventory', icon: Package },
    ...(isAdmin ? [{ name: t('common.users'), href: '/users', icon: Users }] : []),
    { name: t('common.settings'), href: '/settings', icon: Settings },
    { name: t('common.help'), href: '/help', icon: HelpCircle },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <div className="hidden md:flex md:flex-shrink-0">
          <div className="flex flex-col w-64">
            <div className="flex flex-col flex-grow pt-5 overflow-y-auto bg-white border-r">
              <div className="flex items-center flex-shrink-0 px-4">
                <img 
                  src="https://www.strauss-group.com/wp-content/themes/strauss/assets/images/strauss-logo.png"
                  alt="Strauss Logo"
                  className="h-8 object-contain"
                />
              </div>
              <div className="mt-5 flex-grow flex flex-col">
                <nav className="flex-1 px-2 space-y-1">
                  {navigation.map((item) => {
                    const Icon = item.icon;
                    return (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={`${
                          location.pathname === item.href
                            ? 'bg-blue-50 text-blue-600'
                            : 'text-gray-600 hover:bg-gray-50'
                        } group flex items-center px-2 py-2 text-sm font-medium rounded-md`}
                      >
                        <Icon className="mr-3 h-5 w-5" />
                        {item.name}
                      </Link>
                    );
                  })}
                </nav>
              </div>
              <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                <div className="flex-shrink-0 w-full group">
                  <div className="flex items-center justify-between">
                    <button
                      onClick={() => navigate('/profile')}
                      className="flex items-center"
                    >
                      <div>
                        <img
                          className="inline-block h-9 w-9 rounded-full"
                          src={user?.avatar || "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"}
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                          {user?.name}
                        </p>
                        <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                          {t('common.profile')}
                        </p>
                      </div>
                      <User className="ml-auto h-5 w-5 text-gray-400" />
                    </button>
                    <button
                      onClick={handleLogout}
                      className="ml-4 p-2 text-gray-400 hover:text-gray-500 hover:bg-gray-100 rounded-full"
                      title={t('common.logout')}
                    >
                      <LogOut className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Main content */}
        <div className="flex flex-col flex-1 overflow-hidden">
          <main className="flex-1 relative overflow-y-auto focus:outline-none">
            <div className="py-6">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <Outlet />
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;