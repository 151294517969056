import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { InventoryItem } from '../types';

interface InventoryState {
  items: InventoryItem[];
  addItem: (item: Omit<InventoryItem, 'id' | 'serialNumber' | 'createdAt' | 'updatedAt'>) => void;
  updateItem: (item: InventoryItem) => void;
  deleteItem: (itemId: string) => void;
  deleteItems: (itemIds: string[]) => void;
  getNextSerialNumber: () => number;
}

export const useInventoryStore = create<InventoryState>()(
  persist(
    (set, get) => ({
      items: [],
      addItem: (itemData) => {
        const newItem: InventoryItem = {
          id: Math.random().toString(36).substr(2, 9),
          serialNumber: get().getNextSerialNumber(),
          ...itemData,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
        set((state) => ({
          items: [...state.items, newItem],
        }));
      },
      updateItem: (updatedItem) =>
        set((state) => ({
          items: state.items.map((item) =>
            item.id === updatedItem.id
              ? { ...updatedItem, updatedAt: new Date().toISOString() }
              : item
          ),
        })),
      deleteItem: (itemId) =>
        set((state) => ({
          items: state.items.filter((item) => item.id !== itemId),
        })),
      deleteItems: (itemIds) =>
        set((state) => ({
          items: state.items.filter((item) => !itemIds.includes(item.id)),
        })),
      getNextSerialNumber: () => {
        const items = get().items;
        if (items.length === 0) return 1;
        return Math.max(...items.map((item) => item.serialNumber)) + 1;
      },
    }),
    {
      name: 'inventory-storage',
    }
  )
);