import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface SettingsState {
  timeZone: string;
  language: string;
  updateSettings: (settings: Partial<SettingsState>) => void;
}

export const useSettingsStore = create<SettingsState>()(
  persist(
    (set) => ({
      timeZone: 'UTC+2',
      language: 'en',
      updateSettings: (newSettings) => set((state) => ({ ...state, ...newSettings })),
    }),
    {
      name: 'settings-storage',
    }
  )
);