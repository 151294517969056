import React, { useState } from 'react';
import { X, Send } from 'lucide-react';
import { useProjectStore } from '../store/projectStore';
import { useAuthStore } from '../store/authStore';
import { format, parseISO } from 'date-fns';
import type { Project, ProjectDiscussion, ProjectDiscussionComment } from '../types';

interface ProjectDiscussionsProps {
  project: Project;
  onClose: () => void;
}

const ProjectDiscussions: React.FC<ProjectDiscussionsProps> = ({ project, onClose }) => {
  const { user } = useAuthStore();
  const { addDiscussion, addDiscussionComment } = useProjectStore();
  const [newDiscussion, setNewDiscussion] = useState({ title: '', content: '' });
  const [newComment, setNewComment] = useState('');
  const [selectedDiscussion, setSelectedDiscussion] = useState<ProjectDiscussion | null>(null);

  const handleAddDiscussion = (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    const discussion: ProjectDiscussion = {
      id: Math.random().toString(36).substr(2, 9),
      projectId: project.id,
      title: newDiscussion.title,
      content: newDiscussion.content,
      createdAt: new Date().toISOString(),
      createdBy: user.id,
      creatorName: user.name,
      comments: []
    };

    addDiscussion(project.id, discussion);
    setNewDiscussion({ title: '', content: '' });
  };

  const handleAddComment = (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !selectedDiscussion) return;

    const comment: ProjectDiscussionComment = {
      id: Math.random().toString(36).substr(2, 9),
      discussionId: selectedDiscussion.id,
      content: newComment,
      createdAt: new Date().toISOString(),
      createdBy: user.id,
      creatorName: user.name
    };

    addDiscussionComment(project.id, selectedDiscussion.id, comment);
    setNewComment('');
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-medium text-gray-900">דיונים - {project.title}</h3>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-6 w-6" />
            </button>
          </div>

          {/* New Discussion Form */}
          <form onSubmit={handleAddDiscussion} className="mb-6">
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">כותרת</label>
                <input
                  type="text"
                  value={newDiscussion.title}
                  onChange={(e) => setNewDiscussion({ ...newDiscussion, title: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">תוכן</label>
                <textarea
                  value={newDiscussion.content}
                  onChange={(e) => setNewDiscussion({ ...newDiscussion, content: e.target.value })}
                  rows={3}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  required
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-md text-sm font-medium hover:bg-blue-700"
                >
                  פתח דיון חדש
                </button>
              </div>
            </div>
          </form>

          {/* Discussions List */}
          <div className="space-y-6">
            {project.discussions?.map((discussion) => (
              <div key={discussion.id} className="bg-gray-50 rounded-lg p-4">
                <div className="flex justify-between items-start">
                  <div>
                    <h4 className="text-lg font-medium text-gray-900">{discussion.title}</h4>
                    <p className="mt-1 text-sm text-gray-500">{discussion.content}</p>
                    <p className="mt-2 text-xs text-gray-400">
                      נוצר על ידי {discussion.creatorName} | {format(parseISO(discussion.createdAt), 'dd/MM/yyyy HH:mm')}
                    </p>
                  </div>
                </div>

                {/* Comments */}
                <div className="mt-4 space-y-4">
                  {discussion.comments.map((comment) => (
                    <div key={comment.id} className="bg-white rounded-lg p-3">
                      <p className="text-sm text-gray-700">{comment.content}</p>
                      <p className="mt-1 text-xs text-gray-400">
                        {comment.creatorName} | {format(parseISO(comment.createdAt), 'dd/MM/yyyy HH:mm')}
                      </p>
                    </div>
                  ))}

                  {/* Add Comment Form */}
                  {selectedDiscussion?.id === discussion.id && (
                    <form onSubmit={handleAddComment} className="flex items-center space-x-2">
                      <input
                        type="text"
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        placeholder="הוסף תגובה..."
                        className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                      <button
                        type="submit"
                        className="p-2 text-blue-600 hover:text-blue-700"
                        disabled={!newComment.trim()}
                      >
                        <Send className="h-5 w-5" />
                      </button>
                    </form>
                  )}

                  {selectedDiscussion?.id !== discussion.id && (
                    <button
                      onClick={() => setSelectedDiscussion(discussion)}
                      className="text-sm text-blue-600 hover:text-blue-700"
                    >
                      הגב
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDiscussions;