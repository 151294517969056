import React from 'react';
import { BookOpen, Wrench, Users, Bell, Server, CheckSquare, Settings, FolderKanban } from 'lucide-react';

const Help: React.FC = () => {
  const sections = [
    {
      title: 'Getting Started',
      icon: BookOpen,
      content: 'Welcome to the Maintenance Management System. This guide will help you understand the basic features and functionalities of the system.',
      subsections: [
        'Login with your provided credentials',
        'Navigate through different sections using the sidebar',
        'Access your profile settings',
        'View and manage notifications'
      ]
    },
    {
      title: 'Machine Management',
      icon: Server,
      content: 'Learn how to manage and monitor machines in the system.',
      subsections: [
        'Add new machines',
        'Update machine status',
        'Report machine faults',
        'View maintenance history'
      ]
    },
    {
      title: 'Task Management',
      icon: CheckSquare,
      content: 'Understand how to create, assign, and track tasks.',
      subsections: [
        'Create new tasks',
        'Assign tasks to team members',
        'Track task progress',
        'Add comments and attachments'
      ]
    },
    {
      title: 'Project Management',
      icon: FolderKanban,
      content: 'Learn about project planning and tracking features.',
      subsections: [
        'Create new projects',
        'Define project phases',
        'Manage project budget',
        'Track project progress'
      ]
    },
    {
      title: 'User Management',
      icon: Users,
      content: 'For administrators: manage system users and their permissions.',
      subsections: [
        'Add new users',
        'Assign user roles',
        'Manage permissions',
        'Reset passwords'
      ]
    },
    {
      title: 'Alerts and Notifications',
      icon: Bell,
      content: 'Stay informed about important system events and updates.',
      subsections: [
        'Configure alert settings',
        'View active alerts',
        'Set up email notifications',
        'Manage alert priorities'
      ]
    },
    {
      title: 'System Settings',
      icon: Settings,
      content: 'Configure system preferences and global settings.',
      subsections: [
        'Language preferences',
        'Time zone settings',
        'Email notifications',
        'System maintenance'
      ]
    },
    {
      title: 'Troubleshooting',
      icon: Wrench,
      content: 'Common issues and their solutions.',
      subsections: [
        'Login issues',
        'Data synchronization',
        'Report generation',
        'System performance'
      ]
    }
  ];

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-semibold text-gray-900">Help Center</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {sections.map((section) => {
          const Icon = section.icon;
          return (
            <div key={section.title} className="bg-white shadow rounded-lg p-6">
              <div className="flex items-center mb-4">
                <Icon className="h-6 w-6 text-blue-500" />
                <h2 className="ml-3 text-lg font-medium text-gray-900">{section.title}</h2>
              </div>
              <p className="text-gray-600 mb-4">{section.content}</p>
              <ul className="space-y-2">
                {section.subsections.map((subsection) => (
                  <li key={subsection} className="flex items-center text-sm text-gray-600">
                    <span className="mr-2">•</span>
                    {subsection}
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>

      <div className="bg-blue-50 border border-blue-200 rounded-lg p-6 mt-6">
        <h3 className="text-lg font-medium text-blue-900 mb-2">Need Additional Help?</h3>
        <p className="text-blue-700">
          If you need further assistance, please contact our support team:
        </p>
        <ul className="mt-2 space-y-1 text-blue-600">
          <li>Email: support@example.com</li>
          <li>Phone: +1 (555) 123-4567</li>
          <li>Hours: Monday - Friday, 9:00 AM - 5:00 PM</li>
        </ul>
      </div>
    </div>
  );
};

export default Help;