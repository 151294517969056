import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, Mail, Phone, Briefcase, Calendar, Shield } from 'lucide-react';
import { useAuthStore } from '../store/authStore';
import { format, parseISO } from 'date-fns';

const UserDetails: React.FC = () => {
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>();
  const { users } = useAuthStore();
  const userRecord = users.find(u => u.user.id === userId);

  if (!userRecord) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-semibold text-gray-900">User not found</h2>
        <button
          onClick={() => navigate('/users')}
          className="mt-4 inline-flex items-center text-blue-600 hover:text-blue-700"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Users
        </button>
      </div>
    );
  }

  const { user } = userRecord;

  const getRoleColor = (role: string) => {
    switch (role) {
      case 'admin':
        return 'bg-purple-100 text-purple-800';
      case 'contractor':
        return 'bg-orange-100 text-orange-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <button
          onClick={() => navigate('/users')}
          className="inline-flex items-center text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Users
        </button>
      </div>

      <div className="bg-white shadow rounded-lg overflow-hidden">
        {/* User Header */}
        <div className="p-6">
          <div className="flex items-center">
            <img
              className="h-24 w-24 rounded-full object-cover"
              src={user.avatar || "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"}
              alt={user.name}
            />
            <div className="ml-6">
              <h1 className="text-2xl font-semibold text-gray-900">{user.name}</h1>
              <div className="mt-2 flex items-center">
                <span className={`px-3 py-1 rounded-full text-sm font-medium ${getRoleColor(user.role)}`}>
                  {user.role.toUpperCase()}
                </span>
                {user.jobTitle && (
                  <span className="ml-2 text-sm text-gray-500">
                    {user.jobTitle}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* User Information */}
        <div className="border-t border-gray-200 px-6 py-5">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
            <div>
              <dt className="text-sm font-medium text-gray-500 flex items-center">
                <Mail className="h-4 w-4 mr-2" />
                Email
              </dt>
              <dd className="mt-1 text-sm text-gray-900">{user.email}</dd>
            </div>

            <div>
              <dt className="text-sm font-medium text-gray-500 flex items-center">
                <Phone className="h-4 w-4 mr-2" />
                Phone Number
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {user.phoneNumber || 'Not provided'}
              </dd>
            </div>

            <div>
              <dt className="text-sm font-medium text-gray-500 flex items-center">
                <Briefcase className="h-4 w-4 mr-2" />
                Job Title
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {user.jobTitle || 'Not provided'}
              </dd>
            </div>

            <div>
              <dt className="text-sm font-medium text-gray-500 flex items-center">
                <Calendar className="h-4 w-4 mr-2" />
                Last Login
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {format(parseISO(user.lastLogin), 'MMM d, yyyy HH:mm')}
              </dd>
            </div>

            <div>
              <dt className="text-sm font-medium text-gray-500 flex items-center">
                <Shield className="h-4 w-4 mr-2" />
                Role Permissions
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {user.role === 'admin' && (
                  <ul className="list-disc list-inside">
                    <li>Full system access</li>
                    <li>User management</li>
                    <li>System configuration</li>
                    <li>Report generation</li>
                  </ul>
                )}
                {user.role === 'contractor' && (
                  <ul className="list-disc list-inside">
                    <li>Project phase management</li>
                    <li>Task updates</li>
                    <li>File uploads</li>
                    <li>Progress reporting</li>
                  </ul>
                )}
                {user.role === 'user' && (
                  <ul className="list-disc list-inside">
                    <li>View projects and tasks</li>
                    <li>Update assigned tasks</li>
                    <li>Submit reports</li>
                    <li>Basic system access</li>
                  </ul>
                )}
              </dd>
            </div>
          </dl>
        </div>

        {/* Activity Summary */}
        <div className="border-t border-gray-200 px-6 py-5">
          <h3 className="text-lg font-medium text-gray-900">Activity Summary</h3>
          <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-3">
            <div className="bg-gray-50 rounded-lg p-4">
              <dt className="text-sm font-medium text-gray-500">Total Tasks</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">0</dd>
            </div>
            <div className="bg-gray-50 rounded-lg p-4">
              <dt className="text-sm font-medium text-gray-500">Projects</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">0</dd>
            </div>
            <div className="bg-gray-50 rounded-lg p-4">
              <dt className="text-sm font-medium text-gray-500">Comments</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">0</dd>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;