import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import Layout from './components/Layout';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Machines from './pages/Machines';
import MachineFaults from './pages/MachineFaults';
import Tasks from './pages/Tasks';
import TaskDetails from './pages/TaskDetails';
import Alerts from './pages/Alerts';
import Reports from './pages/Reports';
import Users from './pages/Users';
import UserDetails from './pages/UserDetails';
import Settings from './pages/Settings';
import UserProfile from './pages/UserProfile';
import Projects from './pages/Projects';
import ProjectDetails from './pages/ProjectDetails';
import Orders from './pages/Orders';
import OrderDetails from './pages/OrderDetails';
import OrderForm from './pages/OrderForm';
import OrderHistory from './pages/OrderHistory';
import Inventory from './pages/Inventory';
import InventoryDetails from './pages/InventoryDetails';
import InventoryForm from './pages/InventoryForm';
import Help from './pages/Help';
import './i18n';

const queryClient = new QueryClient();

function App() {
  const { i18n } = useTranslation();

  return (
    <div dir={i18n.language === 'he' ? 'rtl' : 'ltr'} className={`${i18n.language === 'he' ? 'font-sans-hebrew' : 'font-sans'}`}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<PrivateRoute />}>
              <Route element={<Layout />}>
                <Route index element={<Dashboard />} />
                <Route path="machines" element={<Machines />} />
                <Route path="machines/:machineId/faults" element={<MachineFaults />} />
                <Route path="tasks" element={<Tasks />} />
                <Route path="tasks/:taskId" element={<TaskDetails />} />
                <Route path="alerts" element={<Alerts />} />
                <Route path="reports" element={<Reports />} />
                <Route path="users" element={<Users />} />
                <Route path="users/:userId" element={<UserDetails />} />
                <Route path="settings" element={<Settings />} />
                <Route path="profile" element={<UserProfile />} />
                <Route path="projects" element={<Projects />} />
                <Route path="projects/:projectId" element={<ProjectDetails />} />
                <Route path="orders" element={<Orders />} />
                <Route path="orders/new" element={<OrderForm />} />
                <Route path="orders/edit/:orderId" element={<OrderForm />} />
                <Route path="orders/:orderId" element={<OrderDetails />} />
                <Route path="orders/history" element={<OrderHistory />} />
                <Route path="inventory" element={<Inventory />} />
                <Route path="inventory/new" element={<InventoryForm />} />
                <Route path="inventory/edit/:itemId" element={<InventoryForm />} />
                <Route path="inventory/:itemId" element={<InventoryDetails />} />
                <Route path="help" element={<Help />} />
              </Route>
            </Route>
          </Routes>
        </Router>
      </QueryClientProvider>
    </div>
  );
}

export default App;