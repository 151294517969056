import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthStore } from '../store/authStore';
import { useOrderStore } from '../store/orderStore';
import { Upload, X, Trash2 } from 'lucide-react';
import type { Order, OrderFile } from '../types';

const OrderForm: React.FC = () => {
  const navigate = useNavigate();
  const { orderId } = useParams<{ orderId: string }>();
  const { user } = useAuthStore();
  const { addOrder, updateOrder, getNextOrderNumber, orders } = useOrderStore();
  const [showSuccessMessage, setShowSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState('');

  const existingOrder = orderId ? orders.find(o => o.id === orderId) : null;

  const [formData, setFormData] = useState<Partial<Order>>(
    existingOrder || {
      title: '',
      description: '',
      priority: 'normal',
      status: 'draft',
      requestDate: new Date().toISOString().split('T')[0],
      estimatedCost: 0,
      quantity: 1,
      supplier: '',
      deliveryDate: '',
      notes: '',
      files: []
    }
  );

  useEffect(() => {
    if (existingOrder) {
      setFormData(existingOrder);
    }
  }, [existingOrder]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    try {
      if (existingOrder) {
        // Update existing order
        const updatedOrder: Order = {
          ...existingOrder,
          ...formData,
          updatedAt: new Date().toISOString()
        };
        updateOrder(updatedOrder);
        setShowSuccessMessage('Order updated successfully!');
      } else {
        // Create new order
        const newOrder: Order = {
          id: Math.random().toString(36).substr(2, 9),
          orderNumber: getNextOrderNumber(),
          ...formData as Required<Omit<Order, 'id' | 'orderNumber'>>,
          requestedBy: user.id,
          requestedByName: user.name,
          createdAt: new Date().toISOString()
        };
        addOrder(newOrder);
        setShowSuccessMessage('Order created successfully!');
      }

      setTimeout(() => {
        navigate('/orders');
      }, 1500);
    } catch (error) {
      setShowErrorMessage(existingOrder ? 'An error occurred while updating the order.' : 'An error occurred while creating the order.');
    }
  };

  const handleNumericInput = (field: keyof Order, value: string) => {
    const numValue = value === '' ? 0 : parseFloat(value);
    if (!isNaN(numValue)) {
      setFormData(prev => ({
        ...prev,
        [field]: numValue
      }));
    }
  };

  return (
    <div className="space-y-6">
      {showSuccessMessage && (
        <div className="bg-green-50 border border-green-200 text-green-600 px-4 py-3 rounded-md">
          {showSuccessMessage}
        </div>
      )}

      {showErrorMessage && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md">
          {showErrorMessage}
        </div>
      )}

      <div className="bg-white shadow rounded-lg">
        <div className="p-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-6">
            {existingOrder ? 'Edit Order' : 'New Order'}
          </h2>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Title</label>
                <input
                  type="text"
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Priority</label>
                <select
                  value={formData.priority}
                  onChange={(e) => setFormData({ ...formData, priority: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                >
                  <option value="low">Low</option>
                  <option value="normal">Normal</option>
                  <option value="high">High</option>
                  <option value="urgent">Urgent</option>
                </select>
              </div>

              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700">Description</label>
                <textarea
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  rows={3}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Quantity</label>
                <input
                  type="number"
                  value={formData.quantity || ''}
                  onChange={(e) => handleNumericInput('quantity', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  required
                  min="1"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Estimated Cost</label>
                <input
                  type="number"
                  value={formData.estimatedCost || ''}
                  onChange={(e) => handleNumericInput('estimatedCost', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  required
                  min="0"
                  step="0.01"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Supplier</label>
                <input
                  type="text"
                  value={formData.supplier}
                  onChange={(e) => setFormData({ ...formData, supplier: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Required By</label>
                <input
                  type="date"
                  value={formData.deliveryDate}
                  onChange={(e) => setFormData({ ...formData, deliveryDate: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>

              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700">Notes</label>
                <textarea
                  value={formData.notes}
                  onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
                  rows={3}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>

              {/* File Upload */}
              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700">Files and Images</label>
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    <Upload className="mx-auto h-12 w-12 text-gray-400" />
                    <div className="flex text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                      >
                        <span>Upload files</span>
                        <input
                          id="file-upload"
                          type="file"
                          multiple
                          accept="image/*,.pdf,.doc,.docx"
                          onChange={(e) => {
                            const files = Array.from(e.target.files || []);
                            const maxSize = 5 * 1024 * 1024; // 5MB
                            const validFiles = files.filter(file => file.size <= maxSize);
                            
                            if (validFiles.length !== files.length) {
                              setShowErrorMessage('Some files were too large. Maximum size is 5MB per file.');
                              return;
                            }

                            Promise.all(
                              validFiles.map(file => new Promise<OrderFile>((resolve) => {
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                  resolve({
                                    id: Math.random().toString(36).substr(2, 9),
                                    name: file.name,
                                    url: reader.result as string,
                                    type: file.type,
                                    uploadedAt: new Date().toISOString()
                                  });
                                };
                                reader.readAsDataURL(file);
                              }))
                            ).then(orderFiles => {
                              setFormData(prev => ({
                                ...prev,
                                files: [...(prev.files || []), ...orderFiles]
                              }));
                              setShowErrorMessage('');
                            });
                          }}
                          className="sr-only"
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-500">
                      PNG, JPG, GIF, PDF, DOC up to 5MB
                    </p>
                  </div>
                </div>
              </div>

              {/* Preview uploaded files */}
              {formData.files && formData.files.length > 0 && (
                <div className="col-span-2 grid grid-cols-2 md:grid-cols-4 gap-4">
                  {formData.files.map((file) => (
                    <div key={file.id} className="relative group">
                      <div className="aspect-square border rounded-lg overflow-hidden">
                        {file.type.startsWith('image/') ? (
                          <img
                            src={file.url}
                            alt={file.name}
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          <div className="w-full h-full flex items-center justify-center bg-gray-100">
                            <svg className="h-8 w-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                            </svg>
                          </div>
                        )}
                      </div>
                      <button
                        type="button"
                        onClick={() => setFormData(prev => ({
                          ...prev,
                          files: prev.files?.filter(f => f.id !== file.id)
                        }))}
                        className="absolute top-1 right-1 p-1 bg-white rounded-full shadow opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        <Trash2 className="h-4 w-4 text-red-500" />
                      </button>
                      <p className="mt-1 text-xs text-gray-500 truncate">{file.name}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={() => navigate('/orders')}
                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded-md text-sm font-medium hover:bg-blue-700"
              >
                {existingOrder ? 'Save Changes' : 'Create Order'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OrderForm;