import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, FileText, DollarSign, Calendar, User, Truck, ClipboardList } from 'lucide-react';
import { useOrderStore } from '../store/orderStore';
import { format, parseISO } from 'date-fns';

const OrderDetails: React.FC = () => {
  const navigate = useNavigate();
  const { orderId } = useParams<{ orderId: string }>();
  const { orders } = useOrderStore();
  const order = orders.find(o => o.id === orderId);

  if (!order) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-semibold text-gray-900">Order not found</h2>
        <button
          onClick={() => navigate('/orders')}
          className="mt-4 inline-flex items-center text-blue-600 hover:text-blue-700"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Orders
        </button>
      </div>
    );
  }

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'approved':
        return 'bg-green-100 text-green-800';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'ordered':
        return 'bg-blue-100 text-blue-800';
      case 'delivered':
        return 'bg-purple-100 text-purple-800';
      case 'rejected':
        return 'bg-red-100 text-red-800';
      case 'cancelled':
        return 'bg-gray-100 text-gray-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <button
          onClick={() => navigate('/orders')}
          className="inline-flex items-center text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Orders
        </button>
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <div className="flex justify-between items-start">
          <div>
            <div className="flex items-center space-x-3">
              <h1 className="text-2xl font-semibold text-gray-900">Order #{order.orderNumber}</h1>
              <span className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(order.status)}`}>
                {order.status.toUpperCase()}
              </span>
            </div>
            <p className="mt-2 text-gray-600">{order.description}</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Cost Information */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex items-center mb-4">
            <DollarSign className="h-6 w-6 text-green-500" />
            <h2 className="text-lg font-medium text-gray-900 ml-2">Cost Information</h2>
          </div>
          <dl className="space-y-2">
            <div className="flex justify-between">
              <dt className="text-sm text-gray-500">Estimated Cost</dt>
              <dd className="text-sm font-medium text-gray-900">
                ${order.estimatedCost.toLocaleString()}
              </dd>
            </div>
            {order.actualCost && (
              <div className="flex justify-between">
                <dt className="text-sm text-gray-500">Actual Cost</dt>
                <dd className="text-sm font-medium text-gray-900">
                  ${order.actualCost.toLocaleString()}
                </dd>
              </div>
            )}
          </dl>
        </div>

        {/* Dates */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex items-center mb-4">
            <Calendar className="h-6 w-6 text-blue-500" />
            <h2 className="text-lg font-medium text-gray-900 ml-2">Important Dates</h2>
          </div>
          <dl className="space-y-2">
            <div className="flex justify-between">
              <dt className="text-sm text-gray-500">Request Date</dt>
              <dd className="text-sm font-medium text-gray-900">
                {format(parseISO(order.requestDate), 'MMM d, yyyy')}
              </dd>
            </div>
            {order.approvalDate && (
              <div className="flex justify-between">
                <dt className="text-sm text-gray-500">Approval Date</dt>
                <dd className="text-sm font-medium text-gray-900">
                  {format(parseISO(order.approvalDate), 'MMM d, yyyy')}
                </dd>
              </div>
            )}
            {order.deliveryDate && (
              <div className="flex justify-between">
                <dt className="text-sm text-gray-500">Delivery Date</dt>
                <dd className="text-sm font-medium text-gray-900">
                  {format(parseISO(order.deliveryDate), 'MMM d, yyyy')}
                </dd>
              </div>
            )}
          </dl>
        </div>

        {/* People */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex items-center mb-4">
            <User className="h-6 w-6 text-purple-500" />
            <h2 className="text-lg font-medium text-gray-900 ml-2">People</h2>
          </div>
          <dl className="space-y-2">
            <div className="flex justify-between">
              <dt className="text-sm text-gray-500">Requested By</dt>
              <dd className="text-sm font-medium text-gray-900">
                {order.requestedByName}
              </dd>
            </div>
            {order.approvedByName && (
              <div className="flex justify-between">
                <dt className="text-sm text-gray-500">Approved By</dt>
                <dd className="text-sm font-medium text-gray-900">
                  {order.approvedByName}
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>

      {/* Additional Details */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Supplier Information */}
        {order.supplier && (
          <div className="bg-white shadow rounded-lg p-6">
            <div className="flex items-center mb-4">
              <Truck className="h-6 w-6 text-indigo-500" />
              <h2 className="text-lg font-medium text-gray-900 ml-2">Supplier Information</h2>
            </div>
            <p className="text-sm text-gray-600">{order.supplier}</p>
          </div>
        )}

        {/* Notes */}
        {order.notes && (
          <div className="bg-white shadow rounded-lg p-6">
            <div className="flex items-center mb-4">
              <ClipboardList className="h-6 w-6 text-yellow-500" />
              <h2 className="text-lg font-medium text-gray-900 ml-2">Notes</h2>
            </div>
            <p className="text-sm text-gray-600">{order.notes}</p>
          </div>
        )}
      </div>

      {/* Attachments */}
      {order.files && order.files.length > 0 && (
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex items-center mb-4">
            <FileText className="h-6  w-6 text-gray-400" />
            <h2 className="text-lg font-medium text-gray-900 ml-2">Attachments</h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {order.files.map((file) => (
              <div key={file.id} className="group relative">
                <a
                  href={file.url}
                  download={file.name}
                  className="block"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="aspect-square border rounded-lg overflow-hidden bg-gray-50 group-hover:bg-gray-100">
                    {file.type.startsWith('image/') ? (
                      <img
                        src={file.url}
                        alt={file.name}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center">
                        <FileText className="h-12 w-12 text-gray-400" />
                      </div>
                    )}
                  </div>
                  <p className="mt-1 text-sm text-gray-500 truncate">{file.name}</p>
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDetails;