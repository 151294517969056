import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, Package, DollarSign, MapPin, AlertTriangle, FileText } from 'lucide-react';
import { useInventoryStore } from '../store/inventoryStore';
import { format, parseISO } from 'date-fns';

const InventoryDetails: React.FC = () => {
  const navigate = useNavigate();
  const { itemId } = useParams<{ itemId: string }>();
  const { items } = useInventoryStore();
  const item = items.find(i => i.id === itemId);

  if (!item) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-semibold text-gray-900">Item not found</h2>
        <button
          onClick={() => navigate('/inventory')}
          className="mt-4 inline-flex items-center text-blue-600 hover:text-blue-700"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Inventory
        </button>
      </div>
    );
  }

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'in-stock':
        return 'bg-green-100 text-green-800';
      case 'low-stock':
        return 'bg-yellow-100 text-yellow-800';
      case 'out-of-stock':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <button
          onClick={() => navigate('/inventory')}
          className="inline-flex items-center text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Inventory
        </button>
        <button
          onClick={() => navigate(`/inventory/edit/${item.id}`)}
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Edit Item
        </button>
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <div className="flex justify-between items-start">
          <div>
            <div className="flex items-center space-x-3">
              <h1 className="text-2xl font-semibold text-gray-900">{item.name}</h1>
              <span className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(item.status)}`}>
                {item.status.replace('-', ' ').toUpperCase()}
              </span>
            </div>
            <p className="mt-2 text-gray-600">SKU: {item.itemId}</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Stock Information */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex items-center mb-4">
            <Package className="h-6 w-6 text-blue-500" />
            <h2 className="text-lg font-medium text-gray-900 ml-2">Stock Information</h2>
          </div>
          <dl className="space-y-2">
            <div className="flex justify-between">
              <dt className="text-sm text-gray-500">Current Quantity</dt>
              <dd className="text-sm font-medium text-gray-900">{item.quantity}</dd>
            </div>
            <div className="flex justify-between">
              <dt className="text-sm text-gray-500">Minimum Quantity</dt>
              <dd className="text-sm font-medium text-gray-900">{item.minQuantity}</dd>
            </div>
            {item.quantity <= item.minQuantity && (
              <div className="mt-2 bg-yellow-50 border border-yellow-200 rounded-md p-3">
                <div className="flex">
                  <AlertTriangle className="h-5 w-5 text-yellow-400" />
                  <p className="ml-2 text-sm text-yellow-700">
                    Stock level is below minimum threshold
                  </p>
                </div>
              </div>
            )}
          </dl>
        </div>

        {/* Price Information */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex items-center mb-4">
            <DollarSign className="h-6 w-6 text-green-500" />
            <h2 className="text-lg font-medium text-gray-900 ml-2">Price Information</h2>
          </div>
          <dl className="space-y-2">
            <div className="flex justify-between">
              <dt className="text-sm text-gray-500">Unit Price</dt>
              <dd className="text-sm font-medium text-gray-900">
                ${item.price.toLocaleString()}
              </dd>
            </div>
            <div className="flex justify-between">
              <dt className="text-sm text-gray-500">Total Value</dt>
              <dd className="text-sm font-medium text-gray-900">
                ${(item.price * item.quantity).toLocaleString()}
              </dd>
            </div>
          </dl>
        </div>

        {/* Location Information */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex items-center mb-4">
            <MapPin className="h-6 w-6 text-purple-500" />
            <h2 className="text-lg font-medium text-gray-900 ml-2">Location</h2>
          </div>
          <p className="text-sm text-gray-600">{item.location}</p>
        </div>
      </div>

      {/* Description and Notes */}
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4">Description</h2>
        <p className="text-sm text-gray-600 whitespace-pre-wrap">{item.notes}</p>
      </div>

      {/* Attachments */}
      {item.files && item.files.length > 0 && (
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex items-center mb-4">
            <FileText className="h-6 w-6 text-gray-400" />
            <h2 className="text-lg font-medium text-gray-900 ml-2">Attachments</h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {item.files.map((file) => (
              <div key={file.id} className="group relative">
                <a
                  href={file.url}
                  download={file.name}
                  className="block"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="aspect-square border rounded-lg overflow-hidden bg-gray-50 group-hover:bg-gray-100">
                    {file.type.startsWith('image/') ? (
                      <img
                        src={file.url}
                        alt={file.name}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center">
                        <FileText className="h-12 w-12 text-gray-400" />
                      </div>
                    )}
                  </div>
                  <p className="mt-1 text-sm text-gray-500 truncate">{file.name}</p>
                </a>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* History */}
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4">Item History</h2>
        <dl className="space-y-2">
          <div className="flex justify-between">
            <dt className="text-sm text-gray-500">Created</dt>
            <dd className="text-sm text-gray-900">
              {format(parseISO(item.createdAt), 'MMM d, yyyy HH:mm')}
            </dd>
          </div>
          <div className="flex justify-between">
            <dt className="text-sm text-gray-500">Last Updated</dt>
            <dd className="text-sm text-gray-900">
              {format(parseISO(item.updatedAt), 'MMM d, yyyy HH:mm')}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default InventoryDetails;