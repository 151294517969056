import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { Project, ProjectDiscussion, ProjectDiscussionComment } from '../types';

interface ProjectState {
  projects: Project[];
  addProject: (project: Project) => void;
  updateProject: (project: Project) => void;
  deleteProject: (projectId: string) => void;
  addDiscussion: (projectId: string, discussion: ProjectDiscussion) => void;
  addDiscussionComment: (projectId: string, discussionId: string, comment: ProjectDiscussionComment) => void;
}

export const useProjectStore = create<ProjectState>()(
  persist(
    (set) => ({
      projects: [],
      addProject: (project) =>
        set((state) => ({
          projects: [...state.projects, project],
        })),
      updateProject: (updatedProject) =>
        set((state) => ({
          projects: state.projects.map((project) =>
            project.id === updatedProject.id ? updatedProject : project
          ),
        })),
      deleteProject: (projectId) =>
        set((state) => ({
          projects: state.projects.filter((project) => project.id !== projectId),
        })),
      addDiscussion: (projectId, discussion) =>
        set((state) => ({
          projects: state.projects.map((project) =>
            project.id === projectId
              ? {
                  ...project,
                  discussions: [...(project.discussions || []), discussion],
                }
              : project
          ),
        })),
      addDiscussionComment: (projectId, discussionId, comment) =>
        set((state) => ({
          projects: state.projects.map((project) =>
            project.id === projectId
              ? {
                  ...project,
                  discussions: project.discussions?.map((discussion) =>
                    discussion.id === discussionId
                      ? {
                          ...discussion,
                          comments: [...discussion.comments, comment],
                        }
                      : discussion
                  ),
                }
              : project
          ),
        })),
    }),
    {
      name: 'project-storage',
    }
  )
);