import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Search, Filter, Download, ShoppingCart, ExternalLink, Edit2 } from 'lucide-react';
import { useOrderStore } from '../store/orderStore';
import { useAuthStore } from '../store/authStore';
import { format, parseISO } from 'date-fns';
import * as XLSX from 'xlsx';

const Orders: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { orders, updateOrder, deleteOrder } = useOrderStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  // Filter out completed orders (they're in order history)
  const activeOrders = orders.filter(order => order.status !== 'completed');

  const filteredOrders = activeOrders.filter(order => {
    const matchesSearch = order.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.orderNumber.toString().includes(searchTerm);

    const matchesStatus = !selectedStatus || order.status === selectedStatus;

    return matchesSearch && matchesStatus;
  });

  const handleUpdateStatus = (orderId: string, newStatus: string) => {
    const order = orders.find(o => o.id === orderId);
    if (!order || !user) return;

    if (newStatus === 'cancelled') {
      if (window.confirm('Are you sure you want to cancel this order? The order will be deleted.')) {
        deleteOrder(orderId);
      }
      return;
    }

    const updatedOrder = {
      ...order,
      status: newStatus,
      ...(newStatus === 'completed' && {
        completedAt: new Date().toISOString(),
        completedBy: user.id,
        completedByName: user.name
      })
    };

    updateOrder(updatedOrder);

    if (newStatus === 'completed') {
      alert('Order marked as completed. You can find it in the Order History.');
    }
  };

  const exportToExcel = () => {
    const exportData = filteredOrders.map(order => ({
      'Order Number': order.orderNumber,
      'Title': order.title,
      'Description': order.description,
      'Status': order.status,
      'Priority': order.priority,
      'Requested By': order.requestedByName,
      'Request Date': format(parseISO(order.requestDate), 'MMM d, yyyy'),
      'Estimated Cost': order.estimatedCost,
      'Supplier': order.supplier,
      'Quantity': order.quantity,
      'Notes': order.notes || ''
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Active Orders');
    XLSX.writeFile(wb, `active_orders_${format(new Date(), 'yyyy-MM-dd')}.xlsx`);
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'approved':
        return 'bg-green-100 text-green-800';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'ordered':
        return 'bg-blue-100 text-blue-800';
      case 'delivered':
        return 'bg-purple-100 text-purple-800';
      case 'rejected':
        return 'bg-red-100 text-red-800';
      case 'cancelled':
        return 'bg-gray-100 text-gray-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Orders</h1>
        <div className="flex items-center space-x-3">
          <button
            onClick={() => navigate('/orders/history')}
            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Order History
          </button>
          <button
            onClick={exportToExcel}
            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            <Download className="h-5 w-5 mr-2 inline" />
            Export
          </button>
          <button
            onClick={() => navigate('/orders/new')}
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            <Plus className="h-5 w-5 mr-2" />
            New Order
          </button>
        </div>
      </div>

      <div className="bg-white rounded-lg p-4">
        <div className="flex items-center space-x-4">
          <div className="flex-1 flex items-center bg-gray-50 rounded-md px-4 py-2">
            <Search className="h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search orders..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="ml-2 flex-1 bg-transparent outline-none"
            />
          </div>
          <select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          >
            <option value="">All Statuses</option>
            <option value="draft">Draft</option>
            <option value="pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="ordered">Ordered</option>
            <option value="delivered">Delivered</option>
            <option value="rejected">Rejected</option>
          </select>
          <button
            onClick={() => {
              setSearchTerm('');
              setSelectedStatus('');
            }}
            className="flex items-center px-3 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            <Filter className="h-4 w-4 mr-2" />
            Clear Filters
          </button>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg divide-y divide-gray-200">
        {filteredOrders.map((order) => (
          <div key={order.id} className="p-6">
            <div className="flex justify-between items-start">
              <div 
                className="flex-1 cursor-pointer"
                onClick={() => navigate(`/orders/${order.id}`)}
              >
                <div className="flex items-center space-x-3">
                  <ShoppingCart className="h-5 w-5 text-gray-400" />
                  <span className="text-sm font-medium text-gray-900">
                    #{order.orderNumber}
                  </span>
                  <h3 className="text-lg font-medium text-gray-900">{order.title}</h3>
                  <span className={`px-2 py-1 text-xs rounded-full ${getStatusColor(order.status)}`}>
                    {order.status.toUpperCase()}
                  </span>
                </div>
                <p className="mt-1 text-sm text-gray-500">{order.description}</p>
                <div className="mt-2 grid grid-cols-2 md:grid-cols-4 gap-4 text-sm text-gray-500">
                  <div>
                    <span className="font-medium">Requested By:</span> {order.requestedByName}
                  </div>
                  <div>
                    <span className="font-medium">Request Date:</span>{' '}
                    {format(parseISO(order.requestDate), 'MMM d, yyyy')}
                  </div>
                  <div>
                    <span className="font-medium">Estimated Cost:</span>{' '}
                    ${order.estimatedCost.toLocaleString()}
                  </div>
                  <div>
                    <span className="font-medium">Quantity:</span> {order.quantity}
                  </div>
                  <div>
                    <span className="font-medium">Supplier:</span> {order.supplier}
                  </div>
                </div>
              </div>
              <div className="ml-4 flex items-center space-x-4">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/orders/edit/${order.id}`);
                  }}
                  className="p-2 text-gray-400 hover:text-gray-500"
                >
                  <Edit2 className="h-5 w-5" />
                </button>
                <select
                  value={order.status}
                  onChange={(e) => handleUpdateStatus(order.id, e.target.value)}
                  className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                >
                  <option value="draft">Draft</option>
                  <option value="pending">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="ordered">Ordered</option>
                  <option value="delivered">Delivered</option>
                  <option value="completed">Completed</option>
                  <option value="rejected">Rejected</option>
                  <option value="cancelled">Cancel Order</option>
                </select>
                <ExternalLink className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>
        ))}

        {filteredOrders.length === 0 && (
          <div className="p-6 text-center text-gray-500">
            No orders found
          </div>
        )}
      </div>
    </div>
  );
};

export default Orders;