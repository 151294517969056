import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Search, ShoppingCart, Download, Filter, Trash2, X } from 'lucide-react';
import { useOrderStore } from '../store/orderStore';
import { useAuthStore } from '../store/authStore';
import { format, parseISO, isWithinInterval, startOfDay, endOfDay } from 'date-fns';
import * as XLSX from 'xlsx';
import type { Order } from '../types';

const OrderHistory: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { orders, deleteOrder } = useOrderStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [selectedPriority, setSelectedPriority] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<string | null>(null);

  // Get unique suppliers from orders
  const suppliers = [...new Set(orders.map(order => order.supplier))].filter(Boolean);

  // Filter completed orders
  const completedOrders = orders.filter(order => order.status === 'completed');

  const filteredOrders = completedOrders.filter(order => {
    const matchesSearch = order.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.orderNumber.toString().includes(searchTerm);

    const matchesSupplier = !selectedSupplier || order.supplier === selectedSupplier;
    const matchesPriority = !selectedPriority || order.priority === selectedPriority;

    let matchesDateRange = true;
    if (startDate && endDate) {
      const orderDate = parseISO(order.completedAt || order.requestDate);
      matchesDateRange = isWithinInterval(orderDate, {
        start: startOfDay(parseISO(startDate)),
        end: endOfDay(parseISO(endDate))
      });
    }

    return matchesSearch && matchesSupplier && matchesPriority && matchesDateRange;
  });

  const exportToExcel = () => {
    const exportData = filteredOrders.map(order => ({
      'Order Number': order.orderNumber,
      'Title': order.title,
      'Description': order.description,
      'Status': order.status,
      'Priority': order.priority,
      'Requested By': order.requestedByName,
      'Request Date': format(parseISO(order.requestDate), 'MMM d, yyyy'),
      'Completion Date': order.completedAt ? format(parseISO(order.completedAt), 'MMM d, yyyy') : 'N/A',
      'Estimated Cost': order.estimatedCost,
      'Actual Cost': order.actualCost || 'N/A',
      'Supplier': order.supplier,
      'Quantity': order.quantity,
      'Notes': order.notes || ''
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Completed Orders');
    XLSX.writeFile(wb, `order_history_${format(new Date(), 'yyyy-MM-dd')}.xlsx`);
  };

  const handleDeleteOrder = (orderId: string) => {
    deleteOrder(orderId);
    setShowDeleteConfirm(null);
  };

  const clearFilters = () => {
    setSearchTerm('');
    setStartDate('');
    setEndDate('');
    setSelectedSupplier('');
    setSelectedPriority('');
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <button
            onClick={() => navigate('/orders')}
            className="mr-4 text-gray-600 hover:text-gray-900"
          >
            <ArrowLeft className="h-5 w-5" />
          </button>
          <h1 className="text-2xl font-semibold text-gray-900">Order History</h1>
        </div>
        <button
          onClick={exportToExcel}
          className="flex items-center px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          <Download className="h-5 w-5 mr-2" />
          Export Filtered Results
        </button>
      </div>

      <div className="bg-white rounded-lg p-4 space-y-4">
        {/* Search and Filters */}
        <div className="flex flex-col md:flex-row gap-4">
          <div className="flex-1">
            <div className="flex items-center bg-gray-50 rounded-md px-4 py-2">
              <Search className="h-5 w-5 text-gray-400" />
              <input
                type="text"
                placeholder="Search completed orders..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="ml-2 flex-1 bg-transparent outline-none"
              />
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
              <span>to</span>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-wrap gap-4 items-center">
          <select
            value={selectedSupplier}
            onChange={(e) => setSelectedSupplier(e.target.value)}
            className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          >
            <option value="">All Suppliers</option>
            {suppliers.map(supplier => (
              <option key={supplier} value={supplier}>{supplier}</option>
            ))}
          </select>

          <select
            value={selectedPriority}
            onChange={(e) => setSelectedPriority(e.target.value)}
            className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          >
            <option value="">All Priorities</option>
            <option value="low">Low</option>
            <option value="normal">Normal</option>
            <option value="high">High</option>
            <option value="urgent">Urgent</option>
          </select>

          <button
            onClick={clearFilters}
            className="flex items-center px-3 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            <Filter className="h-4 w-4 mr-2" />
            Clear Filters
          </button>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg divide-y divide-gray-200">
        {filteredOrders.map((order) => (
          <div key={order.id} className="p-6">
            <div className="flex justify-between items-start">
              <div 
                className="flex-1 cursor-pointer"
                onClick={() => navigate(`/orders/${order.id}`)}
              >
                <div className="flex items-center space-x-3">
                  <ShoppingCart className="h-5 w-5 text-gray-400" />
                  <span className="text-sm font-medium text-gray-900">
                    #{order.orderNumber}
                  </span>
                  <h3 className="text-lg font-medium text-gray-900">{order.title}</h3>
                  <span className="px-2 py-1 text-xs rounded-full bg-green-100 text-green-800">
                    COMPLETED
                  </span>
                </div>
                <p className="mt-1 text-sm text-gray-500">{order.description}</p>
                <div className="mt-2 grid grid-cols-2 md:grid-cols-4 gap-4 text-sm text-gray-500">
                  <div>
                    <span className="font-medium">Requested By:</span> {order.requestedByName}
                  </div>
                  <div>
                    <span className="font-medium">Request Date:</span>{' '}
                    {format(parseISO(order.requestDate), 'MMM d, yyyy')}
                  </div>
                  <div>
                    <span className="font-medium">Completed Date:</span>{' '}
                    {order.completedAt ? format(parseISO(order.completedAt), 'MMM d, yyyy') : 'N/A'}
                  </div>
                  <div>
                    <span className="font-medium">Estimated Cost:</span>{' '}
                    ${order.estimatedCost.toLocaleString()}
                  </div>
                  {order.actualCost && (
                    <div>
                      <span className="font-medium">Actual Cost:</span>{' '}
                      ${order.actualCost.toLocaleString()}
                    </div>
                  )}
                  <div>
                    <span className="font-medium">Quantity:</span> {order.quantity}
                  </div>
                  <div>
                    <span className="font-medium">Supplier:</span> {order.supplier}
                  </div>
                </div>
              </div>
              {user?.role === 'admin' && (
                <div className="ml-4">
                  <button
                    onClick={() => setShowDeleteConfirm(order.id)}
                    className="p-2 text-red-600 hover:text-red-900"
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}

        {filteredOrders.length === 0 && (
          <div className="p-6 text-center text-gray-500">
            No completed orders found
          </div>
        )}
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-900">Delete Order</h3>
              <button
                onClick={() => setShowDeleteConfirm(null)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            <p className="text-sm text-gray-500 mb-4">
              Are you sure you want to delete this order? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowDeleteConfirm(null)}
                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={() => handleDeleteOrder(showDeleteConfirm)}
                className="px-4 py-2 bg-red-600 text-white rounded-md text-sm font-medium hover:bg-red-700"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderHistory;