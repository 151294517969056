import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertCircle, Clock, Calendar, ExternalLink } from 'lucide-react';
import { format, parseISO, isAfter, isBefore, addDays, subDays, differenceInDays } from 'date-fns';
import { useMachineStore } from '../store/machineStore';
import { useTaskStore } from '../store/taskStore';

const Alerts: React.FC = () => {
  const navigate = useNavigate();
  const { faults } = useMachineStore();
  const { tasks } = useTaskStore();
  const now = new Date();
  const oneWeekAgo = subDays(now, 7);
  const nextWeek = addDays(now, 7);

  // Get machines with unresolved faults older than a week
  const longstandingFaults = faults.filter(fault => 
    fault.status !== 'resolved' && 
    isBefore(parseISO(fault.createdAt), oneWeekAgo)
  );

  // Get overdue tasks
  const overdueTasks = tasks.filter(task =>
    task.status !== 'completed' &&
    task.status !== 'rejected' &&
    isBefore(parseISO(task.targetDate), now)
  );

  // Get upcoming tasks due within next 7 days
  const upcomingTasks = tasks.filter(task => {
    if (task.status === 'completed' || task.status === 'rejected') return false;
    
    const dueDate = parseISO(task.targetDate);
    const daysUntilDue = differenceInDays(dueDate, now);
    
    return daysUntilDue >= 0 && daysUntilDue <= 7;
  }).sort((a, b) => {
    // Sort by days remaining (ascending)
    const daysA = differenceInDays(parseISO(a.targetDate), now);
    const daysB = differenceInDays(parseISO(b.targetDate), now);
    return daysA - daysB;
  });

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-semibold text-gray-900">Alerts</h1>

      {/* Long-standing Faults */}
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:px-6 bg-red-50">
          <div className="flex items-center">
            <AlertCircle className="h-6 w-6 text-red-500 mr-2" />
            <h3 className="text-lg font-medium text-red-800">
              Long-standing Machine Faults
            </h3>
          </div>
          <p className="mt-1 text-sm text-red-600">
            Machines with unresolved issues for over a week
          </p>
        </div>
        <div className="border-t border-gray-200">
          {longstandingFaults.length === 0 ? (
            <p className="p-4 text-sm text-gray-500">No long-standing faults</p>
          ) : (
            <ul className="divide-y divide-gray-200">
              {longstandingFaults.map((fault) => (
                <li
                  key={fault.id}
                  className="px-4 py-4 hover:bg-gray-50 cursor-pointer"
                  onClick={() => navigate(`/machines/${fault.machineId}/faults`)}
                >
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm font-medium text-gray-900">
                        {fault.description}
                      </p>
                      <p className="text-sm text-gray-500">
                        Reported on {format(parseISO(fault.createdAt), 'MMM d, yyyy')}
                      </p>
                    </div>
                    <ExternalLink className="h-5 w-5 text-gray-400" />
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {/* Overdue Tasks */}
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:px-6 bg-orange-50">
          <div className="flex items-center">
            <Clock className="h-6 w-6 text-orange-500 mr-2" />
            <h3 className="text-lg font-medium text-orange-800">
              Overdue Tasks
            </h3>
          </div>
          <p className="mt-1 text-sm text-orange-600">
            Tasks that have passed their target completion date
          </p>
        </div>
        <div className="border-t border-gray-200">
          {overdueTasks.length === 0 ? (
            <p className="p-4 text-sm text-gray-500">No overdue tasks</p>
          ) : (
            <ul className="divide-y divide-gray-200">
              {overdueTasks.map((task) => (
                <li
                  key={task.id}
                  className="px-4 py-4 hover:bg-gray-50 cursor-pointer"
                  onClick={() => navigate(`/tasks/${task.id}`)}
                >
                  <div className="flex items-center justify-between">
                    <div>
                      <div className="flex items-center space-x-2">
                        <span className="text-sm font-medium text-gray-900">
                          #{task.taskNumber}
                        </span>
                        <span className="text-sm font-medium text-gray-900">
                          {task.title}
                        </span>
                        <span className={`px-2 py-1 text-xs rounded-full ${
                          task.priority === 'high'
                            ? 'bg-red-100 text-red-800'
                            : task.priority === 'normal'
                            ? 'bg-yellow-100 text-yellow-800'
                            : 'bg-green-100 text-green-800'
                        }`}>
                          {task.priority.toUpperCase()}
                        </span>
                      </div>
                      <p className="text-sm text-gray-500">
                        Due {format(parseISO(task.targetDate), 'MMM d, yyyy')}
                      </p>
                    </div>
                    <ExternalLink className="h-5 w-5 text-gray-400" />
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {/* Upcoming Tasks */}
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:px-6 bg-blue-50">
          <div className="flex items-center">
            <Calendar className="h-6 w-6 text-blue-500 mr-2" />
            <h3 className="text-lg font-medium text-blue-800">
              Upcoming Tasks
            </h3>
          </div>
          <p className="mt-1 text-sm text-blue-600">
            Tasks due within the next 7 days
          </p>
        </div>
        <div className="border-t border-gray-200">
          {upcomingTasks.length === 0 ? (
            <p className="p-4 text-sm text-gray-500">No upcoming tasks</p>
          ) : (
            <ul className="divide-y divide-gray-200">
              {upcomingTasks.map((task) => {
                const daysUntilDue = differenceInDays(parseISO(task.targetDate), now);
                return (
                  <li
                    key={task.id}
                    className="px-4 py-4 hover:bg-gray-50 cursor-pointer"
                    onClick={() => navigate(`/tasks/${task.id}`)}
                  >
                    <div className="flex items-center justify-between">
                      <div>
                        <div className="flex items-center space-x-2">
                          <span className="text-sm font-medium text-gray-900">
                            #{task.taskNumber}
                          </span>
                          <span className="text-sm font-medium text-gray-900">
                            {task.title}
                          </span>
                          <span className={`px-2 py-1 text-xs rounded-full ${
                            task.priority === 'high'
                              ? 'bg-red-100 text-red-800'
                              : task.priority === 'normal'
                              ? 'bg-yellow-100 text-yellow-800'
                              : 'bg-green-100 text-green-800'
                          }`}>
                            {task.priority.toUpperCase()}
                          </span>
                          <span className="text-sm text-orange-600 font-medium">
                            {daysUntilDue === 0 ? 'Due today' : `${daysUntilDue} day${daysUntilDue === 1 ? '' : 's'} left`}
                          </span>
                        </div>
                        <p className="text-sm text-gray-500">
                          Due {format(parseISO(task.targetDate), 'MMM d, yyyy')}
                        </p>
                      </div>
                      <ExternalLink className="h-5 w-5 text-gray-400" />
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default Alerts;