import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, PieChart, Pie, Cell } from 'recharts';
import { Calendar, Filter, Download } from 'lucide-react';
import { useMachineStore } from '../store/machineStore';
import { useTaskStore } from '../store/taskStore';
import { startOfMonth, endOfMonth, parseISO, format, eachMonthOfInterval, subMonths } from 'date-fns';
import * as XLSX from 'xlsx';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const Reports: React.FC = () => {
  const { machines, faults } = useMachineStore();
  const { tasks } = useTaskStore();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Calculate machine status statistics
  const machineStatusStats = [
    { name: 'Working', value: machines.filter(m => m.status === 'working').length },
    { name: 'Maintenance', value: machines.filter(m => m.status === 'maintenance').length },
    { name: 'Not Working', value: machines.filter(m => m.status === 'not-working').length }
  ];

  // Calculate task status statistics
  const taskStatusStats = [
    { name: 'Pending', value: tasks.filter(t => t.status === 'pending').length },
    { name: 'In Progress', value: tasks.filter(t => t.status === 'in-progress').length },
    { name: 'Completed', value: tasks.filter(t => t.status === 'completed').length },
    { name: 'Rejected', value: tasks.filter(t => t.status === 'rejected').length }
  ];

  // Calculate monthly statistics
  const now = new Date();
  const sixMonthsAgo = subMonths(now, 5);
  const monthStarts = eachMonthOfInterval({
    start: sixMonthsAgo,
    end: now
  });

  const monthlyStats = monthStarts.map(monthStart => {
    const monthEnd = endOfMonth(monthStart);
    const monthFaults = faults.filter(fault => {
      const faultDate = parseISO(fault.createdAt);
      return faultDate >= monthStart && faultDate <= monthEnd;
    });

    const monthTasks = tasks.filter(task => {
      const taskDate = parseISO(task.createdAt);
      return taskDate >= monthStart && taskDate <= monthEnd;
    });

    return {
      month: format(monthStart, 'MMM yyyy'),
      faults: monthFaults.length,
      tasks: monthTasks.length,
      completedTasks: monthTasks.filter(t => t.status === 'completed').length
    };
  });

  // Department statistics
  const departmentStats = tasks.reduce((acc: { [key: string]: number }, task) => {
    acc[task.department] = (acc[task.department] || 0) + 1;
    return acc;
  }, {});

  const departmentData = Object.entries(departmentStats).map(([name, value]) => ({
    name,
    value
  }));

  const exportToExcel = () => {
    // Create workbook
    const wb = XLSX.utils.book_new();

    // Machine Status Sheet
    const machineStatusWS = XLSX.utils.json_to_sheet(machineStatusStats);
    XLSX.utils.book_append_sheet(wb, machineStatusWS, "Machine Status");

    // Task Status Sheet
    const taskStatusWS = XLSX.utils.json_to_sheet(taskStatusStats);
    XLSX.utils.book_append_sheet(wb, taskStatusWS, "Task Status");

    // Monthly Activity Sheet
    const monthlyStatsWS = XLSX.utils.json_to_sheet(monthlyStats);
    XLSX.utils.book_append_sheet(wb, monthlyStatsWS, "Monthly Activity");

    // Department Distribution Sheet
    const departmentWS = XLSX.utils.json_to_sheet(departmentData);
    XLSX.utils.book_append_sheet(wb, departmentWS, "Department Distribution");

    // Detailed Machine List
    const machineDetails = machines.map(machine => ({
      'Serial Number': machine.serialNumber,
      'Name': machine.name,
      'Status': machine.status,
      'Type': machine.type,
      'Location': machine.location,
      'Maintenance Schedule': machine.maintenanceSchedule,
      'Next Maintenance': machine.nextMaintenanceDate,
      'Last Update': format(parseISO(machine.lastUpdate), 'yyyy-MM-dd HH:mm')
    }));
    const machinesWS = XLSX.utils.json_to_sheet(machineDetails);
    XLSX.utils.book_append_sheet(wb, machinesWS, "Machine Details");

    // Detailed Task List
    const taskDetails = tasks.map(task => ({
      'Task Number': task.taskNumber,
      'Title': task.title,
      'Description': task.description,
      'Department': task.department,
      'Priority': task.priority,
      'Status': task.status,
      'Created At': format(parseISO(task.createdAt), 'yyyy-MM-dd HH:mm'),
      'Target Date': format(parseISO(task.targetDate), 'yyyy-MM-dd'),
      'Created By': task.creatorName
    }));
    const tasksWS = XLSX.utils.json_to_sheet(taskDetails);
    XLSX.utils.book_append_sheet(wb, tasksWS, "Task Details");

    // Detailed Fault List
    const faultDetails = faults.map(fault => ({
      'Machine ID': fault.machineId,
      'Description': fault.description,
      'Priority': fault.priority,
      'Status': fault.status,
      'Created At': format(parseISO(fault.createdAt), 'yyyy-MM-dd HH:mm'),
      'Resolved At': fault.resolvedAt ? format(parseISO(fault.resolvedAt), 'yyyy-MM-dd HH:mm') : 'Not Resolved'
    }));
    const faultsWS = XLSX.utils.json_to_sheet(faultDetails);
    XLSX.utils.book_append_sheet(wb, faultsWS, "Fault Details");

    // Generate filename with current date
    const fileName = `machine_log_report_${format(new Date(), 'yyyy-MM-dd')}.xlsx`;

    // Save the file
    XLSX.writeFile(wb, fileName);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Reports</h1>
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <Calendar className="h-5 w-5 text-gray-400" />
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="border rounded-md px-2 py-1"
            />
            <span>to</span>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="border rounded-md px-2 py-1"
            />
          </div>
          <button className="flex items-center px-3 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50">
            <Filter className="h-4 w-4 mr-2" />
            Filter
          </button>
          <button
            onClick={exportToExcel}
            className="flex items-center px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
          >
            <Download className="h-4 w-4 mr-2" />
            Export to Excel
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Machine Status Distribution */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Machine Status Distribution</h2>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={machineStatusStats}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {machineStatusStats.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Task Status Distribution */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Task Status Distribution</h2>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={taskStatusStats}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {taskStatusStats.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Monthly Activity */}
        <div className="bg-white p-6 rounded-lg shadow lg:col-span-2">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Monthly Activity</h2>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={monthlyStats}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar name="Faults" dataKey="faults" fill="#FF8042" />
                <Bar name="Tasks Created" dataKey="tasks" fill="#0088FE" />
                <Bar name="Tasks Completed" dataKey="completedTasks" fill="#00C49F" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Department Distribution */}
        <div className="bg-white p-6 rounded-lg shadow lg:col-span-2">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Tasks by Department</h2>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={departmentData} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" width={150} />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" name="Tasks" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;