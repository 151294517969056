import React, { useState } from 'react';
import { X, Upload, Trash2 } from 'lucide-react';
import { useAuthStore } from '../store/authStore';
import type { Project, ProjectPhase, ProjectRisk, ProjectFile } from '../types';

interface ProjectFormProps {
  project?: Project | null;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Partial<Project>) => void;
}

const ProjectForm: React.FC<ProjectFormProps> = ({ project, isOpen, onClose, onSubmit }) => {
  const { users } = useAuthStore();
  const [formData, setFormData] = useState<Partial<Project>>(
    project || {
      title: '',
      objective: '',
      startDate: '',
      endDate: '',
      status: 'planned',
      budget: 0,
      teamMembers: [],
      phases: [],
      risks: [],
      files: [],
      requiredResources: '',
      communicationChannels: '',
      reportingFrequency: 'weekly'
    }
  );

  const handleFieldChange = (field: keyof Project, value: any) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleAddPhase = () => {
    const newPhase: ProjectPhase = {
      id: Math.random().toString(36).substr(2, 9),
      name: '',
      description: '',
      startDate: '',
      endDate: '',
      status: 'planned',
      cost: 0,
      contractor: ''
    };

    handleFieldChange('phases', [...(formData.phases || []), newPhase]);
  };

  const handleUpdatePhase = (index: number, field: keyof ProjectPhase, value: any) => {
    const updatedPhases = [...(formData.phases || [])];
    updatedPhases[index] = { ...updatedPhases[index], [field]: value };
    handleFieldChange('phases', updatedPhases);
  };

  const handleRemovePhase = (index: number) => {
    const updatedPhases = [...(formData.phases || [])];
    updatedPhases.splice(index, 1);
    handleFieldChange('phases', updatedPhases);
  };

  const handleAddRisk = () => {
    const newRisk: ProjectRisk = {
      id: Math.random().toString(36).substr(2, 9),
      description: '',
      impact: 'low',
      probability: 'low',
      mitigation: ''
    };

    handleFieldChange('risks', [...(formData.risks || []), newRisk]);
  };

  const handleUpdateRisk = (index: number, field: keyof ProjectRisk, value: any) => {
    const updatedRisks = [...(formData.risks || [])];
    updatedRisks[index] = { ...updatedRisks[index], [field]: value };
    handleFieldChange('risks', updatedRisks);
  };

  const handleRemoveRisk = (index: number) => {
    const updatedRisks = [...(formData.risks || [])];
    updatedRisks.splice(index, 1);
    handleFieldChange('risks', updatedRisks);
  };

  const contractors = users
    .filter(u => u.user.role === 'contractor')
    .map(u => ({
      id: u.user.id,
      name: u.user.name
    }));

  const teamMembers = users.map(u => ({
    id: u.user.id,
    name: u.user.name
  }));

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-medium text-gray-900">
              {project ? 'Edit Project' : 'New Project'}
            </h3>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-6 w-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Basic Information */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Project Name</label>
                <input
                  type="text"
                  value={formData.title}
                  onChange={(e) => handleFieldChange('title', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Budget</label>
                <input
                  type="number"
                  value={formData.budget}
                  onChange={(e) => handleFieldChange('budget', parseFloat(e.target.value))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  required
                  min="0"
                  step="0.01"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Start Date</label>
                <input
                  type="date"
                  value={formData.startDate}
                  onChange={(e) => handleFieldChange('startDate', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">End Date</label>
                <input
                  type="date"
                  value={formData.endDate}
                  onChange={(e) => handleFieldChange('endDate', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Status</label>
                <select
                  value={formData.status}
                  onChange={(e) => handleFieldChange('status', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                >
                  <option value="planned">Planned</option>
                  <option value="in-progress">In Progress</option>
                  <option value="completed">Completed</option>
                  <option value="on-hold">On Hold</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Team Members</label>
                <select
                  multiple
                  value={formData.teamMembers?.map(m => m.id) || []}
                  onChange={(e) => {
                    const selectedIds = Array.from(e.target.selectedOptions, option => option.value);
                    const selectedMembers = teamMembers.filter(member => selectedIds.includes(member.id));
                    handleFieldChange('teamMembers', selectedMembers);
                  }}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  size={4}
                >
                  {teamMembers.map(member => (
                    <option key={member.id} value={member.id}>
                      {member.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Project Objective</label>
              <textarea
                value={formData.objective}
                onChange={(e) => handleFieldChange('objective', e.target.value)}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                required
              />
            </div>

            {/* Project Phases */}
            <div>
              <div className="flex justify-between items-center mb-4">
                <h4 className="text-lg font-medium text-gray-900">Project Phases</h4>
                <button
                  type="button"
                  onClick={handleAddPhase}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md text-sm font-medium hover:bg-blue-700"
                >
                  Add Phase
                </button>
              </div>
              <div className="space-y-4">
                {formData.phases?.map((phase, index) => (
                  <div key={phase.id} className="border rounded-lg p-4">
                    <div className="flex justify-between items-start mb-4">
                      <h5 className="text-sm font-medium text-gray-900">Phase {index + 1}</h5>
                      <button
                        type="button"
                        onClick={() => handleRemovePhase(index)}
                        className="text-red-600 hover:text-red-700"
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Name</label>
                        <input
                          type="text"
                          value={phase.name}
                          onChange={(e) => handleUpdatePhase(index, 'name', e.target.value)}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Cost</label>
                        <input
                          type="number"
                          value={phase.cost}
                          onChange={(e) => handleUpdatePhase(index, 'cost', parseFloat(e.target.value))}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          required
                          min="0"
                          step="0.01"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Start Date</label>
                        <input
                          type="date"
                          value={phase.startDate}
                          onChange={(e) => handleUpdatePhase(index, 'startDate', e.target.value)}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">End Date</label>
                        <input
                          type="date"
                          value={phase.endDate}
                          onChange={(e) => handleUpdatePhase(index, 'endDate', e.target.value)}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Status</label>
                        <select
                          value={phase.status}
                          onChange={(e) => handleUpdatePhase(index, 'status', e.target.value)}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        >
                          <option value="planned">Planned</option>
                          <option value="in-progress">In Progress</option>
                          <option value="completed">Completed</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Contractor</label>
                        <select
                          value={phase.contractor}
                          onChange={(e) => handleUpdatePhase(index, 'contractor', e.target.value)}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        >
                          <option value="">Select a contractor</option>
                          {contractors.map(contractor => (
                            <option key={contractor.id} value={contractor.name}>
                              {contractor.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-span-2">
                        <label className="block text-sm font-medium text-gray-700">Description</label>
                        <textarea
                          value={phase.description}
                          onChange={(e) => handleUpdatePhase(index, 'description', e.target.value)}
                          rows={2}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          required
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Project Risks */}
            <div>
              <div className="flex justify-between items-center mb-4">
                <h4 className="text-lg font-medium text-gray-900">Project Risks</h4>
                <button
                  type="button"
                  onClick={handleAddRisk}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md text-sm font-medium hover:bg-blue-700"
                >
                  Add Risk
                </button>
              </div>
              <div className="space-y-4">
                {formData.risks?.map((risk, index) => (
                  <div key={risk.id} className="border rounded-lg p-4">
                    <div className="flex justify-between items-start mb-4">
                      <h5 className="text-sm font-medium text-gray-900">Risk {index + 1}</h5>
                      <button
                        type="button"
                        onClick={() => handleRemoveRisk(index)}
                        className="text-red-600 hover:text-red-700"
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="col-span-2">
                        <label className="block text-sm font-medium text-gray-700">Description</label>
                        <textarea
                          value={risk.description}
                          onChange={(e) => handleUpdateRisk(index, 'description', e.target.value)}
                          rows={2}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Impact</label>
                        <select
                          value={risk.impact}
                          onChange={(e) => handleUpdateRisk(index, 'impact', e.target.value)}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        >
                          <option value="low">Low</option>
                          <option value="medium">Medium</option>
                          <option value="high">High</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Probability</label>
                        <select
                          value={risk.probability}
                          onChange={(e) => handleUpdateRisk(index, 'probability', e.target.value)}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        >
                          <option value="low">Low</option>
                          <option value="medium">Medium</option>
                          <option value="high">High</option>
                        </select>
                      </div>
                      <div className="col-span-2">
                        <label className="block text-sm font-medium text-gray-700">Mitigation Strategy</label>
                        <textarea
                          value={risk.mitigation}
                          onChange={(e) => handleUpdateRisk(index, 'mitigation', e.target.value)}
                          rows={2}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          required
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* File Upload */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Files and Images</label>
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <Upload className="mx-auto h-12 w-12 text-gray-400" />
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                    >
                      <span>Upload files</span>
                      <input
                        id="file-upload"
                        type="file"
                        multiple
                        accept="image/*,.pdf,.doc,.docx"
                        onChange={(e) => {
                          const files = Array.from(e.target.files || []);
                          const maxSize = 5 * 1024 * 1024; // 5MB
                          const validFiles = files.filter(file => file.size <= maxSize);
                          
                          if (validFiles.length !== files.length) {
                            alert('Some files were too large. Maximum size is 5MB per file.');
                          }

                          Promise.all(
                            validFiles.map(file => new Promise<ProjectFile>((resolve) => {
                              const reader = new FileReader();
                              reader.onloadend = () => {
                                resolve({
                                  id: Math.random().toString(36).substr(2, 9),
                                  name: file.name,
                                  url: reader.result as string,
                                  type: file.type,
                                  uploadedAt: new Date().toISOString()
                                });
                              };
                              reader.readAsDataURL(file);
                            }))
                          ).then(projectFiles => {
                            handleFieldChange('files', [...(formData.files || []), ...projectFiles]);
                          });
                        }}
                        className="sr-only"
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-gray-500">
                    PNG, JPG, GIF, PDF, DOC up to 5MB
                  </p>
                </div>
              </div>

              {/* Preview uploaded files */}
              {formData.files && formData.files.length > 0 && (
                <div className="mt-4 grid grid-cols-2 md:grid-cols-4 gap-4">
                  {formData.files.map((file) => (
                    <div key={file.id} className="relative group">
                      <div className="aspect-square border rounded-lg overflow-hidden">
                        {file.type.startsWith('image/') ? (
                          <img
                            src={file.url}
                            alt={file.name}
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          <div className="w-full h-full flex items-center justify-center bg-gray-100">
                            <svg className="h-8 w-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                            </svg>
                          </div>
                        )}
                      </div>
                      <button
                        type="button"
                        onClick={() => handleFieldChange(
                          'files',
                          formData.files?.filter(f => f.id !== file.id)
                        )}
                        className="absolute top-1 right-1 p-1 bg-white rounded-full shadow opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        <Trash2 className="h-4 w-4 text-red-500" />
                      </button>
                      <p className="mt-1 text-xs text-gray-500 truncate">{file.name}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Additional Information */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Required Resources</label>
                <textarea
                  value={formData.requiredResources}
                  onChange={(e) => handleFieldChange('requiredResources', e.target.value)}
                  rows={3}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Communication Channels</label>
                <textarea
                  value={formData.communicationChannels}
                  onChange={(e) => handleFieldChange('communicationChannels', e.target.value)}
                  rows={3}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Reporting Frequency</label>
              <select
                value={formData.reportingFrequency}
                onChange={(e) => handleFieldChange('reportingFrequency', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="biweekly">Bi-weekly</option>
                <option value="monthly">Monthly</option>
              </select>
            </div>

            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded-md text-sm font-medium hover:bg-blue-700"
              >
                {project ? 'Save Changes' : 'Create Project'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProjectForm;