import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { Task, TaskFile } from '../types';

interface TaskState {
  tasks: Task[];
  addTask: (task: Omit<Task, 'id' | 'taskNumber' | 'createdAt' | 'notes'>) => void;
  updateTask: (task: Task) => void;
  deleteTask: (taskId: string) => void;
  addNote: (note: TaskNote) => void;
  getNextTaskNumber: () => number;
}

export const useTaskStore = create<TaskState>()(
  persist(
    (set, get) => ({
      tasks: [],
      addTask: (taskData) => {
        const newTask: Task = {
          id: Math.random().toString(36).substr(2, 9),
          taskNumber: get().getNextTaskNumber(),
          createdAt: new Date().toISOString(),
          notes: [],
          ...taskData,
        };
        set((state) => ({
          tasks: [...state.tasks, newTask],
        }));
      },
      updateTask: (updatedTask) =>
        set((state) => ({
          tasks: state.tasks.map((task) =>
            task.id === updatedTask.id ? updatedTask : task
          ),
        })),
      deleteTask: (taskId) =>
        set((state) => ({
          tasks: state.tasks.filter((task) => task.id !== taskId),
        })),
      addNote: (note) =>
        set((state) => ({
          tasks: state.tasks.map((task) =>
            task.id === note.taskId
              ? { ...task, notes: [...task.notes, note] }
              : task
          ),
        })),
      getNextTaskNumber: () => {
        const tasks = get().tasks;
        if (tasks.length === 0) return 1;
        return Math.max(...tasks.map((task) => task.taskNumber)) + 1;
      },
    }),
    {
      name: 'task-storage',
    }
  )
);